import { ActionCreator, Organisation } from '../types'
import * as api from '../util/api'

export const getOrganisations: ActionCreator = () => async (dispatch, getState) => {
	const organisations = (await api.getOrganisations()) || ([] as Organisation[])
	dispatch({ type: 'ORGANISATIONS_FETCHED', payload: organisations })
}

export const setSelectedOrganisation: ActionCreator = (org: Organisation) => async (dispatch, getState) => {
	console.log('setSelectedOrganisation', org)
	dispatch({ type: 'ORGANISATION_SELECTED', payload: org })
}

export const selectOrganisationForEdit: ActionCreator = (org: Organisation) => async (dispatch, getState) => {
	console.log('selectOrganistaionForEdit', org)
	dispatch({ type: 'ORGANISATION_EDIT_SELECTED', payload: org })
}

export const updateOrganisation: ActionCreator = (org: Organisation) => async (dispatch, getState) => {
	console.log('updateOrganisation', org)
	await api.updateOrganisation(org)
	dispatch({ type: 'ORGANISATION_UPDATED', payload: org })
}
