import React, { FunctionComponent } from 'react'

type PreloaderProps = {
	//
}

const Preloader: FunctionComponent<PreloaderProps> = props => {
	return (
		<div className="preloader-bricks">
			<div className="loadingio-spinner-bricks-jwpk1bb72tj">
				<div className="ldio-wuc0evm2wbs">
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	)
}

export default Preloader
