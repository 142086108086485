// import { createRoot } from 'react-dom/client'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import React from 'react'

import store from './store'
import registerServiceWorker from './util/registerServiceWorker'

import Root from './Root'

registerServiceWorker()

function renderApp() {
	// Render Root component into the <div id="root"> element
	const element = document.getElementById('root')
	ReactDOM.render(
		<Provider store={store}>
			<Root />
		</Provider>,
		element
	)
}

renderApp()
