import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import parse from 'try-json'

import { Incident, Log as LogType, Role, StateTree } from '../../types'

import ActiveIncidentBar from '../modules/ActiveIncidentBar'
import AddIncidentLog from '../modules/AddIncidentLog'
import GoogleMapPopup from '../modules/GoogleMapPopup'
import Scroller from '../elements/Scroller'
import Page from '../elements/Page'
import Log from '../elements/Log'
import { getLogs } from '../../actions/logs'
import PreloaderSection from '../modules/PreloaderSection'
import { useInterval } from 'react-use'

type LogsProps = {
	logs: LogType[]
	incident?: Incident
	roles: Role[]
	online: boolean
	getLogs: (incidentid: string) => Promise<void>
}

const Logs: FunctionComponent<LogsProps> = props => {
	const { logs, incident, roles, getLogs, online } = props
	const [selectedRole, setSelectedRole] = useState('')
	const [showMap, setShowMap] = useState(false)
	const [selectedLog, setSelectedLog] = useState<LogType>(null)
	const scrollerRef = useRef<HTMLDivElement>()

	const fetchLogs = async () => getLogs(incident.id)

	useInterval(fetchLogs, 5000)
	const logCount = useRef(0)

	useEffect(() => {
		if (!scrollerRef.current) return
		if (logs.length !== logCount.current) {
			setTimeout(() => {
				if (!scrollerRef.current) return
				scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight
			}, 1)
			logCount.current = logs.length
		}
	}, [logs])

	if (!incident) {
		return (
			<Page className="logs" title="Logs" showBackButton>
				<ActiveIncidentBar />
				<p>The incident log can be accessed from this page when an incident is in progress.</p>
			</Page>
		)
	}

	const onClickLog = (log: LogType) => {
		if (log.type === 'location') {
			setSelectedLog(log)
			setShowMap(true)
		}
	}
	const onCloseMap = () => {
		setShowMap(false)
		setSelectedLog(null)
	}

	let _logs = [...logs]
	_logs.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))

	if (selectedRole) _logs = _logs.filter(l => l.roleid === selectedRole)

	_logs = _logs.filter(l => l.type !== 'location' || parse(l.content)?.lat)

	const logElems = _logs.map(log => <Log key={log.id} log={log} onClick={onClickLog} />)

	return (
		<Page className="logs" title="Incident Log" showBackButton>
			<ActiveIncidentBar />
			<select value={selectedRole} onChange={e => setSelectedRole(e.target.value)}>
				<option key="" value={''}>
					All roles
				</option>
				{roles.map(r => (
					<option key={r.id} value={r.id}>
						{r.title}
					</option>
				))}
			</select>
			<PreloaderSection className="logs__container" dataloader={fetchLogs}>
				<Scroller ref={scrollerRef} className="logs__container">
					{logElems}
				</Scroller>
			</PreloaderSection>
			{online && <AddIncidentLog />}
			{showMap && <GoogleMapPopup location={JSON.parse(selectedLog.content)} onClose={onCloseMap} online={online} />}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	logs: state.logs,
	incident: state.activeIncident,
	roles: state.roles || [],
	online: state.online,
})
const actions = {
	getLogs: getLogs,
}
export default connect(mapStateToProps, actions)(Logs)
