import React, { FunctionComponent, useRef } from 'react'
import { Location } from '../../types'
import { formatLocation } from '../../util/formatLocation'

import MyGoogleMap from '../elements/MyGoogleMap'
import ValueRow from '../elements/ValueRow'

type GoogleMapPopupProps = {
	location: Location
	onClose: () => void
	online?: boolean
}

const GoogleMapPopup: FunctionComponent<GoogleMapPopupProps> = props => {
	const { location, onClose, online } = props
	const elem = useRef(null)
	const onClick = e => {
		if (e.target === e.currentTarget) {
			onClose()
		}
	}
	if (!online) {
		return (
			<div className="google-map-popup" onClick={onClick} ref={elem}>
				<p>Map cannot be shown while offline.</p>
			</div>
		)
	}
	return (
		<div className="google-map-popup" onClick={onClick} ref={elem}>
			<MyGoogleMap defaultLocation={location} height={300} getUserLocation={false} />
		</div>
	)
}

export default GoogleMapPopup
