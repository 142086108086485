import { v4 as uuid } from 'uuid'

import { ActionCreator, Contact, ContactGroup } from '../types'
import { getContacts as getContactsApi, getContactGroups as getContactGroupsApi } from '../util/api'
import * as api from '../util/api'
import { alert, confirm } from '../util/alerts'

export const createIncidentContact: ActionCreator = (contact: Contact) => async (dispatch, getState) => {
	const orgId = getState().login?.organisationid || ''
	const activeIncidentId = getState().activeIncident?.id || ''
	contact.groupid = 'incident_' + activeIncidentId
	if (!contact.id) contact.id = contact.name.replace(/[^a-zA-Z]/g, '').toLowerCase() + '_' + uuid().substr(0, 8)
	dispatch({ type: 'CONTACT_UPDATED', payload: contact })
	await api.updateContact(contact, orgId)
}

export const getContacts: ActionCreator<string> = (orgId?: string) => async (dispatch, getState) => {
	const contacts = (await getContactsApi(orgId)) || ([] as Contact[])
	dispatch({ type: 'CONTACTS_FETCHED', payload: contacts })
}

export const getContactGroups: ActionCreator = (orgId?: string) => async (dispatch, getState) => {
	const groups = (await getContactGroupsApi(orgId)) || ([] as Contact[])
	dispatch({ type: 'CONTACT_GROUPS_FETCHED', payload: groups })
}

export const updateContact: ActionCreator = (contact: Contact) => async (dispatch, getState) => {
	const orgId = getState().selectedOrganisation?.id || ''
	if (!contact.id) contact.id = contact.name.replace(/[^a-zA-Z]/g, '').toLowerCase() + '_' + uuid().substr(0, 8)
	await api.updateContact(contact, orgId)
	dispatch({ type: 'CONTACT_UPDATED', payload: contact })
	// getContacts(orgId)(dispatch, getState)
}

export const selectContact: ActionCreator = (contact: Contact) => async (dispatch, getState) => {
	console.log('selectContact', contact)
	dispatch({ type: 'CONTACT_SELECTED', payload: contact })
}

export const deleteContact: ActionCreator = (contact: Contact) => async (dispatch, getState) => {
	const orgId = getState().selectedOrganisation?.id || ''
	confirm({ text: 'Are you sure you want to delete this contact?' }).then(async result => {
		if (!result) return
		await api.deleteContact(contact, orgId)
		dispatch({ type: 'CONTACT_DELETED', payload: contact })
		alert({ title: 'Contact deleted' })
	})
}

export const selectContactGroup: ActionCreator = (group: ContactGroup) => async (dispatch, getState) => {
	dispatch({ type: 'CONTACT_GROUP_SELECTED', payload: group })
}

export const updateContactGroup: ActionCreator = (group: ContactGroup) => async (dispatch, getState) => {
	if (!group.name) return
	if (!group.id) group.id = group.name.replace(/[^a-zA-Z]/g, '').toLowerCase() + '_' + uuid().substr(0, 8)
	const orgId = getState().selectedOrganisation?.id || ''
	await api.updateContactGroup(group, orgId)
	dispatch({ type: 'CONTACT_GROUP_UPDATED', payload: group })
}

export const deleteContactGroup: ActionCreator = (group: ContactGroup) => async (dispatch, getState) => {
	const orgId = getState().selectedOrganisation?.id || ''
	confirm({ text: 'Are you sure you want to delete this group? \n All contacts in this group will be deleted!' }).then(
		async result => {
			if (!result) return
			await api.deleteContactGroup(group, orgId)
			dispatch({ type: 'CONTACT_GROUP_DELETED', payload: group })
			alert({ title: 'Group deleted' })
		}
	)
}
