import { ActionCreator } from '../types'
import { getContent } from '../util/api'

export const toggleDarkMode: ActionCreator = () => async (dispatch, getState) => {
	let darkMode = localStorage.getItem('darkmode') !== 'false'
	darkMode = !darkMode
	localStorage.setItem('darkmode', darkMode.toString())
	document.body.classList.toggle('dark', darkMode)
	document.body.classList.toggle('light', !darkMode)
}

let lastOnline = false
export const checkOnline: ActionCreator = () => async (dispatch, getState) => {
	const online = navigator.onLine
	if (online !== lastOnline) {
		lastOnline = online
		console.log(online ? 'ONLINE' : 'OFFLINE')
	}
	document.body.classList.toggle('offline', !online)
	const state = getState()
	if (state.online !== online) {
		dispatch({ type: 'ONLINE', payload: online })
	}
}

export const fetchContent: ActionCreator = () => async (dispatch, getState) => {
	const content = await getContent()
	dispatch({ type: 'MISC_GOT_CONTENT', payload: content })
	return content
}

export const clearErrors: ActionCreator = () => async (dispatch, getState) => {
	dispatch({ type: 'MISC_CLEAR_ERRORS' })
}

export default {
	toggleDarkMode,
	checkOnline,
}
