import React, { FunctionComponent } from 'react'
import { WebsiteInfoItem } from '../../types'
import Card from './Card'

type WebsitesListProps = {
	list: WebsiteInfoItem[]
}

const WebsitesList: FunctionComponent<WebsitesListProps> = ({ list }) => {
	return (
		<>
			{list.map((item, index) => (
				<Card key={index} title={item.title} url={item.url}></Card>
			))}
		</>
	)
}

export default WebsitesList
