import React, { FunctionComponent, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import { closeIncident } from '../../../actions/incidents'
import { Form, Incident, StateTree } from '../../../types'
import { alert, confirm } from '../../../util/alerts'
import IncidentForm from '../../modules/IncidentForm'
import Button from '../../elements/Button'
import Page from '../../elements/Page'
import Scroller from '../../elements/Scroller'

type IncidentDetailsProps = {
	incidents: Incident[]
	closeIncident: (id: string) => void
	online?: boolean
}

const IncidentDetails: FunctionComponent<IncidentDetailsProps> = props => {
	const { incidents, closeIncident, online } = props

	// Get contact group ID from path parameters
	const navigate = useNavigate()
	let { incidentid } = useParams()
	const incident = incidents.find(i => i.id === incidentid)
	const form = useRef<Form>({ data: incident || {} })

	const onSubmit = () => {
		confirm({
			text: 'Please confirm that you wish to close this incident?',
			confirmText: 'Close incident',
		}).then(async confirmed => {
			if (!confirmed) return
			await closeIncident(incidentid)
			alert({ title: 'Incident closed', icon: 'success' })
			navigate('/menu')
		})
	}

	const onClickEdit = () => {
		navigate(`/incidents/${incidentid}/edit`)
	}

	return (
		<Page className="incident-details" title="Incident details" showBackButton padding={false}>
			<Scroller>
				<IncidentForm form={form.current} enabled={false} />
			</Scroller>
			{online && (
				<footer>
					<Button onClick={onClickEdit}>Update</Button>
					<Button onClick={onSubmit}>Close incident</Button>
				</footer>
			)}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	incidents: state.incidents,
	online: state.online,
})
const actions = {
	closeIncident: closeIncident,
}
export default connect(mapStateToProps, actions)(IncidentDetails)
