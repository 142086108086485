import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { StateTree } from '../../types'
import Button from '../elements/Button'
import DarkModeSwitch from '../elements/DarkModeSwitch'
import Page from '../elements/Page'
import Row from '../elements/Row'
import Scroller from '../elements/Scroller'

type SettingsProps = {}

const Settings: FunctionComponent<SettingsProps> = props => {
	const navigate = useNavigate()

	return (
		<Page className="settings" showBackButton title="Settings" depth={2}>
			<Scroller>
				<Row>
					Dark Mode
					<DarkModeSwitch />
				</Row>
			</Scroller>
			<footer>
				<Button onClick={() => navigate('/')}>Log out</Button>
			</footer>
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({})
const actions = {}
export default connect(mapStateToProps, actions)(Settings)
