import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { getContacts, selectContact, getContactGroups, deleteContact } from '../../../actions/contacts'
import { Contact, ContactGroup, Organisation, StateTree } from '../../../types'
import OrganisationSelector from '../../modules/OrganisationSelector'
import PreloaderSection from '../../modules/PreloaderSection'
import Button from '../../elements/Button'
import Page from '../../elements/Page'
import TableLink from '../../elements/TableLink'
import ContactDetailsPopup from '../../popups/ContactDetailsPopup'

type ContactsProps = {
	getContacts: (orgId?: string) => Promise<any>
	getContactGroups: (orgId?: string) => Promise<any>
	selectContact: (contact: Contact) => void
	deleteContact: (contact: Contact) => Promise<any>
	contacts: Contact[]
	contactGroups: ContactGroup[]
	selectedContact?: Contact
	selectedOrganisation?: Organisation
}

const Contacts: FunctionComponent<ContactsProps> = props => {
	const {
		getContacts,
		contacts,
		selectedContact,
		selectContact,
		contactGroups,
		getContactGroups,
		deleteContact,
		selectedOrganisation,
	} = props
	const onClickCreate = () => selectContact({} as Contact)

	const headerButton = <Button onClick={onClickCreate}>Create</Button>

	contacts.sort((a, b) => {
		const parentNameA = contactGroups.find(g => g.id === a.groupid)?.name || a.groupid || ''
		const parentNameB = contactGroups.find(g => g.id === b.groupid)?.name || b.groupid || ''
		if (parentNameA > parentNameB) return 1
		if (parentNameA < parentNameB) return -1
		if (a.name > b.name) return 1
		if (a.name < b.name) return -1
		return 0
	})

	const orgId = selectedOrganisation?.id
	return (
		<Page title="Contacts" showBackButton={false} headerButton={headerButton}>
			<OrganisationSelector />
			<PreloaderSection reloadkey={orgId} dataloader={() => Promise.all([getContacts(orgId), getContactGroups(orgId)])}>
				<table>
					<thead>
						<tr>
							<th>Group</th>
							<th>Name</th>
							<th>Title</th>
							<th>Phone number</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{contacts.map(contact => {
							let groupName = contactGroups.find(g => g.id === contact.groupid)?.name || ''
							if (contact.groupid.startsWith('incident_')) {
								groupName = 'Live incident contacts'
							}

							return (
								<tr key={contact.id}>
									<td style={{ width: 160 }}>{groupName}</td>
									<td>{contact.name}</td>
									<td>{contact.title}</td>
									<td style={{ width: 160 }}>{contact.phone}</td>
									<td style={{ width: 160 }}>
										<TableLink label="Edit" onClick={() => selectContact(contact)} />
										<TableLink label="Delete" onClick={() => deleteContact(contact)} />
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</PreloaderSection>
			{selectedContact && <ContactDetailsPopup />}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return {
		contacts: state.contacts || [],
		contactGroups: state.contact_groups || [],
		selectedContact: state.selectedContact,
		selectedOrganisation: state.selectedOrganisation,
	}
}
const actions = {
	getContacts: getContacts,
	selectContact: selectContact,
	deleteContact: deleteContact,
	getContactGroups: getContactGroups,
}
export default connect(mapStateToProps, actions)(Contacts)
