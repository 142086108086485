import { LoginDetails, Reducer } from '../types'
import { LoginResult } from '../util/api'

const INITIAL_STATE = { error: null, inprogress: false }

export const LOGIN_IN_PROGRESS: Reducer = (state, action) => {
	return { ...state, login: { error: null, inprogress: true, userid: action.payload.userid, schoolid: null } }
}
export const LOGIN_FAILED: Reducer = (state, action) => {
	return { ...state, login: { ...state.login, inprogress: false, error: action.payload, userid: null, schoolid: null } }
}
export const LOGIN_SUCCESS: Reducer = (state, action) => {
	const loginDetails: LoginDetails = action.payload
	return { ...state, login: { ...state.login, ...loginDetails, inprogress: false, error: null }, page: 'menu' }
}

const reducers = {
	LOGIN_FAILED,
	LOGIN_SUCCESS,
	LOGIN_IN_PROGRESS,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	if (!newState.login) {
		newState.login = INITIAL_STATE
	}
	return newState
}

export default switchcase
