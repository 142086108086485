import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Error, Form, Organisation, StateTree } from '../../types'
// import { updateOrganisation, selectOrganisation } from '../../actions/Organisations'
import { clearErrors } from '../../actions/misc'
import Input from '../elements/Input'
import Popup from '../elements/Popup'
import { useMount } from 'react-use'
import { selectOrganisationForEdit, updateOrganisation } from '../../actions/organisations'

type OrganisationDetailsPopupProps = {
	updateOrganisation: (Organisation: Organisation) => Promise<any>
	selectOrganisation: (Organisation: Organisation) => void
	selectedOrganisation: Organisation
	clearErrors: () => void
	errors?: Error[]
}

type FormOrganisation = Partial<Organisation>

const OrganisationDetailsPopup: FunctionComponent<OrganisationDetailsPopupProps> = props => {
	// const { selectedOrganisation, selectOrganisation, clearErrors, errors } = props
	const { updateOrganisation, selectedOrganisation, selectOrganisation, clearErrors, errors } = props

	const initialFormData: FormOrganisation = { ...(selectedOrganisation || {}) }
	const form = useRef<Form>({ data: initialFormData })

	useMount(() => {
		clearErrors()
	})

	useEffect(() => {
		form.current.data = { ...(selectedOrganisation || {}) }
	}, [selectedOrganisation])

	const newOrganisation = !selectedOrganisation.id

	const [loading, setLoading] = useState(false)

	const onSave = async () => {
		setLoading(true)
		try {
			const details = {}
			for (const key in form.current.data) {
				details[key.replace('new-', '')] = form.current.data[key]
			}
			const data: Organisation = { ...selectedOrganisation, ...details }
			await updateOrganisation(data)
			selectOrganisation(null)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}

	return (
		<Popup
			className="organisation-details-popup"
			onClose={() => selectOrganisation(null)}
			onSave={onSave}
			loading={loading}
		>
			<form autoComplete="off">
				<Input id="id" label="ID" form={form.current} enabled={newOrganisation} preventautofill />
				<Input id="name" label="Name" form={form.current} preventautofill />
			</form>
			{errors && errors.length > 0 && (
				<div className="popup__errors">
					{errors.map((err, i) => (
						<p key={i}>{err.message}</p>
					))}
				</div>
			)}
		</Popup>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	selectedOrganisation: state.selectedOrganisationForEdit,
	errors: state.errors,
})
const actions = {
	updateOrganisation: updateOrganisation,
	selectOrganisation: selectOrganisationForEdit,
	clearErrors: clearErrors,
}
export default connect(mapStateToProps, actions)(OrganisationDetailsPopup)
