import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { ContactGroup, StateTree } from '../../../types'
import ListItem from '../../elements/ListItem'
import Page from '../../elements/Page'

type ContactGroupsProps = {
	contactGroups: ContactGroup[]
}

// =================================================================================================

const ContactGroups: FunctionComponent<ContactGroupsProps> = props => {
	const { contactGroups } = props
	const _groups = contactGroups.filter(g => !g.parentgroupid)
	return (
		<Page className="contact-groups" title="Contact groups" showBackButton={false}>
			<ListItem key="live" icon="bolt" target={`/contacts/live`} title="Live incident contacts" colour="red" />
			{_groups.map(({ id, name, icon, colour = 'green' }) => (
				<ListItem key={id} icon={icon} target={`/contacts/${id}`} title={name} colour={colour} />
			))}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	contactGroups: state.contact_groups || [],
})
const actions = {}
export default connect(mapStateToProps, actions)(ContactGroups)
