import React, { FunctionComponent } from 'react'

import { unstable_HistoryRouter as HistoryRouter, useLocation } from 'react-router-dom'

import history from './util/history'

import PasswordReset from './components/pages/PasswordReset'
import Admin from './Admin'
import App from './App'

const Root: FunctionComponent = () => (
	<>
		<HistoryRouter history={history}>
			<RootRouter />
		</HistoryRouter>
	</>
)

// Here we decide whether to show the standard web app (App) or the admin console (Admin) or the
// password reset page (PasswordReset)
const RootRouter: FunctionComponent = () => {
	const location = useLocation()
	if (location.pathname.startsWith('/reset')) return <PasswordReset />
	if (location.pathname.startsWith('/admin')) return <Admin />
	return <App />
}

export default Root
