import React, { FunctionComponent } from 'react'
import { StateTree, Organisation } from '../../../types'
import PreloaderSection from '../../modules/PreloaderSection'
import Page from '../../elements/Page'

// import { getOrganisations, selectOrganisation, deleteOrganisation } from '../../../actions/organisations'
import { getOrganisations, selectOrganisationForEdit } from '../../../actions/organisations'
import { connect } from 'react-redux'
// import OrganisationDetailsPopup from '../../popups/OrganisationDetailsPopup'
import Button from '../../elements/Button'
import Link from '../../elements/Link'
import OrganisationDetailsPopup from '../../popups/OrganisationDetailsPopup'

type OrganisationsProps = {
	organisations: Organisation[]
	selectedOrganisation: Organisation
	getOrganisations: () => Promise<Organisation>
	selectOrganisation: (organisation: Organisation) => void
	deleteOrganisation: (organisation: Organisation) => void
}

const Organisations: FunctionComponent<OrganisationsProps> = props => {
	const { getOrganisations, selectOrganisation, organisations, selectedOrganisation, deleteOrganisation } = props
	const onClickCreate = () => {
		selectOrganisation({} as Organisation)
	}
	const headerButton = <Button onClick={onClickCreate}>Create new organisation</Button>

	organisations.sort((a, b) => (a.name > b.name ? 1 : -1))

	console.log('selectedOrganisation', selectedOrganisation)
	return (
		<Page title="Organisations" headerButton={headerButton} showBackButton={false}>
			<PreloaderSection dataloader={getOrganisations}>
				<table>
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{organisations.map(organisation => (
							<tr key={organisation.id}>
								<td>{organisation.id}</td>
								<td>{organisation.name}</td>
								<td>
									<Link onClick={() => selectOrganisation(organisation)}>Edit</Link>
									{/* <Link onClick={() => deleteOrganisation(organisation)}>Delete</Link> */}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</PreloaderSection>
			{selectedOrganisation && <OrganisationDetailsPopup />}
		</Page>
	)
}
// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return {
		organisations: state.organisations || [],
		selectedOrganisation: state.selectedOrganisationForEdit,
	}
}
const actions = {
	getOrganisations: getOrganisations,
	selectOrganisation: selectOrganisationForEdit,
	deleteOrganisation: null,
}
export default connect(mapStateToProps, actions)(Organisations)
