import React, { FunctionComponent, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Form, StateTree, ContactGroup, Organisation } from '../../types'
import { updateContactGroup, selectContactGroup, getContactGroups } from '../../actions/contacts'
import Input from '../elements/Input'
import Popup from '../elements/Popup'
import Select from '../elements/Select'
import { useMount } from 'react-use'
import Icon from '../elements/Icon'

type ContactGroupDetailsPopupProps = {
	selectedContactGroup: ContactGroup
	selectContactGroup: (contactgroup: ContactGroup) => void
	updateContactGroup: (contactgroup: ContactGroup) => Promise<any>
	contactGroups: ContactGroup[]
	getContactGroups: (orgid?: string) => Promise<any>
	selectedOrganisation?: Organisation
}

export const colours = [
	{ key: 'red', value: 'Red' },
	{ key: 'blue', value: 'Purple' },
	{ key: 'green', value: 'Green' },
	{ key: 'purple', value: 'Magenta' },
	{ key: 'light-blue', value: 'Light blue' },
	{ key: 'orange', value: 'Orange' },
	{ key: 'black', value: 'Black' },
]

const icons = [
	{ key: 'phone', value: 'Phone' },
	{ key: 'fire', value: 'Fire' },
	{ key: 'tv-retro', value: 'Television' },
	{ key: 'shield', value: 'Shield' },
	{ key: 'bolt', value: 'Bolt' },
	{ key: 'user-group', value: 'Two people' },
	{ key: 'users', value: 'Three people' },
	{ key: 'bus-school', value: 'School bus' },
]

const DFT_COLOUR = 'green'
const DFT_ICON = 'phone'

const ContactGroupDetailsPopup: FunctionComponent<ContactGroupDetailsPopupProps> = props => {
	const {
		updateContactGroup,
		selectedContactGroup,
		selectContactGroup,
		contactGroups,
		getContactGroups,
		selectedOrganisation,
	} = props

	const values = { ...selectedContactGroup }
	// Initialise some default values if we're creating a new contact group
	if (Object.keys(values).length === 0) {
		values.parentgroupid = ''
		values.colour = DFT_COLOUR
		values.icon = DFT_ICON
	}
	const form = useRef<Form>({ data: values })

	const [loading, setLoading] = useState(true)

	useMount(async () => {
		setLoading(true)
		await getContactGroups(selectedOrganisation?.id)
		setLoading(false)
	})

	useEffect(() => {
		form.current.data = selectedContactGroup || {}
	}, [selectedContactGroup])

	const newContactGroup = !selectedContactGroup.id

	const onSave = async () => {
		setLoading(true)
		try {
			const data = { ...selectedContactGroup, ...form.current.data }
			if (!data.colour) data.colour = DFT_COLOUR
			if (!data.icon) data.icon = DFT_ICON
			await updateContactGroup(data)
			selectContactGroup(null)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}

	const [selectedIcon, setSelectedIcon] = useState(form.current.data.icon || DFT_ICON)
	const [selectedColour, setSelectedColour] = useState(form.current.data.colour || DFT_COLOUR)

	const groups = contactGroups.map(group => ({ key: group.id, value: group.name }))
	groups.unshift({ key: '', value: 'None' })

	return (
		<Popup
			className="contactgroup-details-popup"
			onClose={() => selectContactGroup(null)}
			onSave={onSave}
			loading={loading}
		>
			<Select id="parentgroupid" label="Parent group" form={form.current} options={groups} />
			<Input id="name" label="Name" form={form.current} enabled={true} />
			<Select
				id="colour"
				label="Colour"
				form={form.current}
				options={colours}
				onChange={val => setSelectedColour(val)}
			/>
			<Select id="icon" label="Icon" form={form.current} options={icons} onChange={val => setSelectedIcon(val)} />
			<Icon name={selectedIcon} color={selectedColour} />
		</Popup>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	selectedContactGroup: state.selectedContactGroup,
	contactGroups: state.contact_groups || [],
	selectedOrganisation: state.selectedOrganisation,
})
const actions = {
	updateContactGroup: updateContactGroup,
	selectContactGroup: selectContactGroup,
	getContactGroups: getContactGroups,
}
export default connect(mapStateToProps, actions)(ContactGroupDetailsPopup)
