import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ContactInfoItem, Content, DetailInfoItem, StateTree, WebsiteInfoItem } from '../../types'

import Page from '../elements/Page'
import WebsitesList from '../elements/WebsitesList'
import Card from '../elements/Card'
import Contact from '../elements/Contact'

// @ts-ignore
const CONTENT_BASE_URL = process.env.CONTENT_BASE_URL

type InformationContentProps = {
	content: Content
	orgId: string
}
const InformationContent: FunctionComponent<InformationContentProps> = ({ content, orgId }) => {
	// Get the path of this page
	const params = useParams()
	const path = (params['*'] || '').split('/')

	const info = content.info || []
	// Get the content for this page out of the global "content" object, based on the path (e.g. fire/evacuation/maps)
	let pageContent = info.find(x => x.path === path[0] || x.name.toLowerCase().split(' ').join('-') === path[0])

	// Now we need to work out what should be displayed on this page
	if (pageContent.type === 'websites') {
		return (
			<Page title={pageContent.name}>
				<WebsitesList list={pageContent.content as WebsiteInfoItem[]} />
			</Page>
		)
	}

	if (pageContent.type === 'detail') {
		const detail = pageContent.content as DetailInfoItem
		return (
			<Page className="info" title={pageContent.name}>
				<h2 style={{ textAlign: 'left', margin: '1rem 2rem', fontSize: '28px' }}>{detail.heading}</h2>
				{detail.subheading ? (
					<h3 style={{ textAlign: 'left', margin: '0rem 2rem 1rem 2rem' }}>{detail.subheading}</h3>
				) : null}
				{detail.description ? <p style={{ textAlign: 'left', margin: '1rem 2rem' }}>{detail.description}</p> : null}
				{detail.cards.map((card, index) => {
					// Find the secret "key" for this image in the content store
					const key = content?.media?.find(m => m.path === card.image)?.key
					// Use this key to build the URL for the image
					const imagePath = key ? CONTENT_BASE_URL + orgId + '/' + key : ''

					return (
						<Card key={index} title={card.name} image={imagePath}>
							{card.text}
						</Card>
					)
				})}
			</Page>
		)
	}

	if (pageContent.type === 'contacts') {
		const contacts = pageContent.content as ContactInfoItem[]
		return (
			<Page title={pageContent.name}>
				{contacts.map((contact, index) => {
					const { name, phone, colour } = contact
					return <Contact key={index} name={name} phone={phone} colour={colour} />
				})}
			</Page>
		)
	}

	return (
		<Page title={pageContent.name}>
			<b>Error:</b> Content misconfigured
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return { content: state?.content || {}, orgId: state?.login?.schoolid || '' }
}
const actions = {}
export default connect(mapStateToProps, actions)(InformationContent)
