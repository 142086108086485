import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { StateTree } from '../../types'

// @ts-ignore
const CONTENT_BASE_URL = process.env.CONTENT_BASE_URL

type MarkdownImageProps = {
	src?: string
	alt?: string
	orgId?: string
	content?: any
}

const MarkdownImage: FunctionComponent<MarkdownImageProps> = props => {
	const { src, orgId, content } = props
	console.log('src', src)

	// Find the secret "key" for this image in the content store
	const key = content?.media?.find(m => m.path === src)?.key

	// Use this key to build the URL for the image
	return <img src={CONTENT_BASE_URL + orgId + '/' + key} />
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return { orgId: state?.login?.schoolid, content: state?.content || {} }
}
const actions = {}
export default connect(mapStateToProps, actions)(MarkdownImage)
