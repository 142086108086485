import React, { CSSProperties, FunctionComponent, useRef, useState } from 'react'
import Panzoom from '@panzoom/panzoom'
import { connect } from 'react-redux'
import { useMount } from 'react-use'

import { ContentMediaItem, StateTree } from '../../types'
import Page from '../elements/Page'
import PreloaderOverlay from '../elements/PreloaderOverlay'
import Row from '../elements/Row'
import Switch from '../elements/Switch'

// @ts-ignore
const CONTENT_BASE_URL = process.env.CONTENT_BASE_URL

type MapProps = {
	content: StateTree['content']
	orgId: string
}

const Map: FunctionComponent<MapProps> = ({ content, orgId }) => {
	const contentMediaItems: ContentMediaItem[] = content.media || []

	// Find the main map image in the list of media, and get the public URL for it
	const mapKey = contentMediaItems.find(m => m.path === 'map.png')?.key || ''
	const mapPath = mapKey && CONTENT_BASE_URL + orgId + '/' + mapKey

	// Find the map key image in the list of media (if it exists), and get the public URL for it
	const keyKey = contentMediaItems.find(m => m.path === 'map__key.png')?.key || ''
	const keyPath = keyKey && CONTENT_BASE_URL + orgId + '/' + keyKey

	const overlays = contentMediaItems.filter(m => m.path.startsWith('map__') && m.path !== 'map__key.png')

	const mapElem = useRef<HTMLImageElement>(null)

	const [loading, setLoading] = useState(Boolean(mapPath))

	useMount(() => {
		setTimeout(() => {
			const panzoom = Panzoom(mapElem.current, {
				maxScale: 5,
				minScale: 1,
				contain: 'outside',
			})
			mapElem.current.parentElement.addEventListener('wheel', panzoom.zoomWithWheel)
			// Not sure why we have to wait another fraction of a second for this bit, but it works.
			setTimeout(() => {
				// Centre the map within the pan-zoom container
				const mapWidth = mapElem.current.clientWidth
				const parentWidth = mapElem.current.parentElement.clientWidth
				const x = (parentWidth - mapWidth) / 2
				panzoom.pan(x, 0)
				panzoom.zoom(1.5)
				setLoading(false)
			}, 200)
		}, 800)
	})

	let className = 'map'
	if (loading) {
		className += ' map--loading'
	}

	const [overlaysToShow, setOverlaysToShow] = useState([])

	// This is some style information applied to the overlay elements
	const absolutePosition = { position: 'absolute', top: 0, left: 0 } as CSSProperties

	return (
		<Page className={className} showBackButton={false} title="Map" depth={2} padding={false}>
			{loading && <PreloaderOverlay />}
			{!mapPath && !loading && <p>No map available</p>}
			<div className="panzoom-parent">
				<div className="map__content" ref={mapElem}>
					{mapPath && <img src={mapPath} alt="Map" />}
					{/* Loop over and display any selected overlay images */}
					{overlays.map(overlay => {
						// Get the public URL for this overlay image (e.g. https://incident360.app/content/desc/83ac5a5164e12ea2a22c7d40040871bb)
						const path = CONTENT_BASE_URL + orgId + '/' + overlay.key
						// The overlay title is part of the filename, e.g. "map__fire exits.png"
						const title = overlay.path.replace('map__', '').replace('.png', '')
						if (overlaysToShow.includes(path)) {
							return <img src={path} alt={title} style={absolutePosition} />
						} else {
							return null
						}
					})}
				</div>
				{keyPath && <img src={keyPath} alt="Key" className="map__key" />}
			</div>

			{/* Loop over and display a switch for each overlay image */}
			{overlays.map(overlay => {
				const path = CONTENT_BASE_URL + orgId + '/' + overlay.key
				const title = overlay.path.replace('map__', '').replace('.png', '')
				return (
					<Row key={path}>
						Show {title}
						<Switch
							value={overlaysToShow.includes(path)}
							onChange={() => {
								if (overlaysToShow.includes(path)) {
									setOverlaysToShow(overlaysToShow.filter(o => o !== path))
								} else {
									setOverlaysToShow([...overlaysToShow, path])
								}
							}}
						/>
					</Row>
				)
			})}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	content: state.content || {},
	orgId: state?.login?.schoolid,
})

const actions = {}
export default connect(mapStateToProps, actions)(Map)
