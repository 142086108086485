import React, { FunctionComponent, useState } from 'react'
import { useMount } from 'react-use'
import { Form } from '../../types'

export type InputProps = {
	id: string
	label: string
	initialValue?: string
	type?: string
	form: Form
	enabled?: boolean
	preventautofill?: boolean
	onChange?: (value: string) => void
}

const Input: FunctionComponent<InputProps> = props => {
	const { id, label, initialValue = '', form, enabled = true, type = 'text', preventautofill, onChange } = props

	// For the initial value of this input field, either use data from the provided "Form", or the provided "initialValue", or an empty string
	const _initialValue = form?.data?.[id] || initialValue || ''
	const [value, setValue] = useState(_initialValue)

	useMount(() => {
		if (initialValue) form.data[id] = initialValue
	})

	const _onChange = e => {
		setValue(e.target.value)
		form.data = form.data || {}
		form.data[id] = e.target.value
		if (onChange) onChange(e.target.value)
	}

	const _label = label + (label.endsWith('?') ? '' : ':')
	const name = preventautofill ? 'new-' + id : id
	return (
		<label>
			{_label}
			<input name={name} type={type} value={value} onChange={_onChange} disabled={!enabled} autoComplete={name} />
		</label>
	)
}

export default Input
