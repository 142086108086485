import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Contact as ContactType, ContactGroup, StateTree } from '../../../types'
import Contact from '../../elements/Contact'
import ListItem from '../../elements/ListItem'
import Page from '../../elements/Page'

type ContactsProps = {
	contacts: ContactType[]
	contactGroups: ContactGroup[]
}

const Contacts: FunctionComponent<ContactsProps> = props => {
	const { contacts, contactGroups } = props
	// Get contact group ID from path parameters
	let { groupid } = useParams()

	let _contacts = contacts.filter(contact => contact.groupid === groupid)
	const groupName = contactGroups.find(d => d.id === groupid)?.name || ''
	const colour = contactGroups.find(d => d.id === groupid)?.colour || 'green'
	console.log(
		'contactGroups.find(d => d.id === groupid)',
		contactGroups.find(d => d.id === groupid)
	)

	_contacts = _contacts.sort((a, b) => {
		const firstName = a.name.split(' ')[0]
		const lastName = a.name.split(' ').pop()
		const firstName2 = b.name.split(' ')[0]
		const lastName2 = b.name.split(' ').pop()

		if (lastName && lastName2 && lastName < lastName2) return -1
		if (lastName && lastName2 && lastName > lastName2) return 1
		if (firstName && firstName2 && firstName < firstName2) return -1
		if (firstName && firstName2 && firstName > firstName2) return 1
		return 0
	})

	const childContactGroups = contactGroups.filter(d => d.parentgroupid === groupid)

	return (
		<Page className="contacts" title="Contacts" subtitle={groupName} showBackButton>
			{childContactGroups.map(({ id, name, icon, colour }) => (
				<ListItem key={id} icon={icon} target={`/contacts/${id}`} title={name} colour={colour} />
			))}
			{_contacts.map(({ id, name, phone, title }) => (
				<Contact key={id} name={name} phone={phone} title={title} colour={colour} />
			))}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	contacts: state.contacts || [],
	contactGroups: state.contact_groups || [],
})
const actions = {}
export default connect(mapStateToProps, actions)(Contacts)
