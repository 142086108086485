import React, { FunctionComponent } from 'react'

import Icon from './Icon'
import { useNavigate } from 'react-router-dom'

type PageHeaderProps = {
	showBackButton?: boolean
	showMenuButton?: boolean
	title?: string
	subtitle?: string
}

const PageHeader: FunctionComponent<PageHeaderProps> = props => {
	const { showBackButton, title, subtitle, showMenuButton, children } = props
	// const { title, subtitle } = props
	const navigate = useNavigate()
	// const showBackButton = false
	const showMenu = !showBackButton && showMenuButton !== false
	const toggleMenu = () => {
		document.body.classList.toggle('menuopen')
	}
	return (
		<div className="page-header">
			{showBackButton ? <Icon name="arrow-left" onClick={() => navigate(-1)} /> : null}
			{showMenu ? <Icon name="bars" onClick={toggleMenu} /> : null}
			<div className="page-header__content">
				{title ? <h1>{title}</h1> : null}
				{subtitle ? <h2>{subtitle}</h2> : null}
			</div>
			{children}
		</div>
	)
}

export default PageHeader
