import React, { FunctionComponent } from 'react'

type IconProps = {
	name: string
	color?: string
	className?: string
	style?: string
	onClick?: () => void
}

const Icon: FunctionComponent<IconProps> = props => {
	let { name, className = '', style = 'light' } = props
	const { color, onClick } = props

	// className += ` icon icon-${name}`

	className += ` icon icon-${name} fa fa-${style} fa-${name}`
	if (color === 'random') {
		className += ' icon--' + ['red', 'green', 'blue'][Math.floor(Math.random() * 3)]
	} else if (color) {
		className += ' icon--' + color
	}

	return <span className={className} onClick={onClick} />
}

export default Icon
