import React, { FunctionComponent, useRef } from 'react'
import { Organisation, StateTree, User } from '../../../types'
import PreloaderSection from '../../modules/PreloaderSection'
import Page from '../../elements/Page'

import { selectUser, deleteUser, getUsers } from '../../../actions/users'
import { connect } from 'react-redux'
import UserDetailsPopup from '../../popups/UserDetailsPopup'
import Button from '../../elements/Button'
import Link from '../../elements/Link'
import OrganisationSelector from '../../modules/OrganisationSelector'

type UsersProps = {
	users: User[]
	selectedUser: User
	getUsers: (orgId: string) => Promise<any>
	selectUser: (user: User) => void
	deleteUser: (user: User) => void
	selectedOrganisation: Organisation
}

const Users: FunctionComponent<UsersProps> = props => {
	const { getUsers, selectUser, users, selectedUser, deleteUser, selectedOrganisation } = props
	const onClickCreate = () => {
		selectUser({} as User)
	}
	const headerButton = <Button onClick={onClickCreate}>Create new user</Button>

	users.sort((a, b) => (a.name > b.name ? 1 : -1))

	const capitalise = (str: string) => {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}
	return (
		<Page title="Users" headerButton={headerButton} showBackButton={false}>
			<OrganisationSelector />
			<PreloaderSection reloadkey={selectedOrganisation?.id} dataloader={() => getUsers(selectedOrganisation?.id)}>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Username</th>
							<th>Email</th>
							<th>Type</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{users.map(user => (
							<tr key={user.id}>
								<td>{user.name}</td>
								<td>{user.id}</td>
								<td>{user.email}</td>
								<td>{capitalise(user.type || 'standard') + ' user'}</td>
								<td>
									<Link onClick={() => selectUser(user)}>Edit</Link>
									<Link onClick={() => deleteUser(user)}>Delete</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</PreloaderSection>
			{selectedUser && <UserDetailsPopup />}
		</Page>
	)
}
// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	users: state.users || [],
	selectedUser: state.selectedUser,
	selectedOrganisation: state.selectedOrganisation,
})

const actions = {
	getUsers: getUsers,
	selectUser: selectUser,
	deleteUser: deleteUser,
}
export default connect(mapStateToProps, actions)(Users)
