import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { Role, StateTree, User, RoleAllocation } from '../../../types'
import ListItem from '../../elements/ListItem'
import Page from '../../elements/Page'

type RolesProps = {
	users: User[]
	roles: Role[]
	userid: string
	allocations: RoleAllocation[]
}

const MANAGER_ROLE_ID = 'incident-manager'

const Roles: FunctionComponent<RolesProps> = props => {
	const { roles, users, userid, allocations } = props

	const isUserManager = Boolean(allocations.find(r => r.roleid === MANAGER_ROLE_ID && r.userid === userid))

	return (
		<Page className="roles" showBackButton={false} title="Team roles" depth={2}>
			<p>
				The following is a list of all Crisis Team roles, and their current allocated member of staff. Click on a role
				to access the actions and responsibilities for that role.
			</p>
			{roles.map(r => {
				const allocatedUsers = allocations
					.filter(a => a.roleid === r.id)
					.map(a => users.find(({ id }) => id === a.userid))
					.filter(u => Boolean(u))
					.map(u => u.name)
					.join(', ')

				// Enable this list item if the user is a manager, or if the user is allocated to this role
				const enabled = isUserManager || Boolean(allocations.find(a => a.roleid === r.id && a.userid === userid))
				const icon = r.icon || 'clipboard-list'

				return (
					<ListItem key={r.id} icon={icon} title={r.title} target={r.id} colour={r.colour} enabled={enabled}>
						{allocatedUsers.length ? allocatedUsers : 'Not allocated'}
					</ListItem>
				)
			})}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	roles: state.roles || [],
	users: state.users || [],
	userid: state.login?.userid,
	allocations: state.role_allocations || [],
})
const actions = {}
export default connect(mapStateToProps, actions)(Roles)
