import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Incident, IncidentHeader, StateTree } from '../../../types'
import formatDateTime from '../../../util/formatDateTime'
import ActiveIncidentBar from '../../modules/ActiveIncidentBar'
import Button from '../../elements/Button'
import ListItem, { ListItemColours } from '../../elements/ListItem'
import Page from '../../elements/Page'
import Row from '../../elements/Row'
import SectionBar from '../../elements/SectionBar'

// @ts-ignore
// import bolt from '../../../../img/bolt.svg'
import bolt from '../../../../img/bolt-white.svg'
import { useMount } from 'react-use'

type IncidentReportsProps = {
	incidents: IncidentHeader[]
	activeIncident: Incident
	online?: boolean
}

const IncidentReports: FunctionComponent<IncidentReportsProps> = props => {
	const navigate = useNavigate()
	const { online, incidents, activeIncident } = props

	const activeIncidents = incidents.filter(incident => !Boolean(incident.closed))
	useMount(() => {
		if (activeIncidents.length === 0) {
			navigate(`/incidents/new`, { replace: true })
		}
	})

	const allowNewIncident = online && !activeIncident

	return (
		<Page className="incident-reports" showBackButton title="Incident reports">
			<ActiveIncidentBar expand>
				<Button onClick={() => navigate(`/incidents/${activeIncident.id}`)}>Full Details</Button>
			</ActiveIncidentBar>
			{allowNewIncident && (
				<Button primary image={bolt} onClick={() => navigate('/incidents/new')} enabled={online}>
					Report New Incident
				</Button>
			)}
		</Page>
	)
}

type IncidentsListProps = {
	incidents: Incident[]
	emptyMessage: string
	title: string
}

const IncidentsList: FunctionComponent<IncidentsListProps> = props => {
	const { incidents, emptyMessage, title } = props
	const { green, red } = ListItemColours

	return incidents.length ? (
		<>
			<SectionBar>{title}</SectionBar>
			{incidents.map((incident: IncidentHeader) => (
				<ListItem
					title={formatDateTime(new Date(incident.timestamp))}
					key={incident.id}
					colour={Boolean(incident.closed) ? green : red}
					target={`/incidents/${incident.id}`}
				>
					{incident.incidentDetails || ''}
				</ListItem>
			))}
		</>
	) : (
		<h3>{emptyMessage}</h3>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	incidents: state.incidents,
	activeIncident: state.activeIncident || null,
	online: state.online,
})
const actions = {}
export default connect(mapStateToProps, actions)(IncidentReports)
