import { Reducer } from '../types'

export function GOTO_PAGE(state, action) {
	return { ...state, page: action.payload.page }
}

const reducers = {
	GOTO_PAGE,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
