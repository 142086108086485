import React, { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import { goToPage } from '../../actions/nav'
import { Incident, StateTree } from '../../types'

import ActiveIncidentBar from '../modules/ActiveIncidentBar'
import MenuTopBar from '../elements/MenuTopBar'
import MenuItem from '../elements/MenuItem'
import Button from '../elements/Button'
import Page from '../elements/Page'

// @ts-ignore
import warning from '../../../img/warning.svg'
// @ts-ignore
import marker from '../../../img/marker.svg'
// @ts-ignore
import phone from '../../../img/phone.svg'
// @ts-ignore
import roles from '../../../img/roles.svg'
// @ts-ignore
import info from '../../../img/info.svg'
// @ts-ignore
import bolt from '../../../img/bolt.svg'
// @ts-ignore
import report from '../../../img/report.svg'

type MenuProps = {
	incident: Incident | null
	content: any
	orgId: string
	userType: string
}

const Menu: FunctionComponent<MenuProps> = props => {
	const { incident, content, orgId, userType } = props
	console.log('content', content)
	const navigate = useNavigate()

	const showIncidentLogButton = Boolean(incident)

	const ViewIncidentLogButton = () => (
		<Button primary image={bolt} onClick={() => navigate('/logs')}>
			View incident log
		</Button>
	)

	const logo = content?.logo ? `https://incident360.app/content/${orgId}/${content?.logo}` : null
	const background = content?.background ? `https://incident360.app/content/${orgId}/${content?.background}` : null
	const mapEnabled = content?.media?.find((m: any) => m.path.startsWith('map.'))

	return (
		<Page className="menu" header={false} padding={false} title="Menu">
			<MenuTopBar showRefresh={userType === 'admin'} />
			<div className="menu__hero">
				<div className="menu__hero__background" style={{ backgroundImage: 'url(' + background + ')' }}></div>
				<div className="menu__hero__shade"></div>
				<div className="menu__hero__logo" style={{ backgroundImage: 'url(' + logo + ')' }}></div>
			</div>
			<ActiveIncidentBar expand />
			{showIncidentLogButton && <ViewIncidentLogButton />}
			<div className="menu__grid">
				<MenuItem image={phone} target="/contacts" title="Contacts" colour="green" />
				<MenuItem image={report} target="/incidents" title="Report Incident" colour="blue" />
				<MenuItem image={roles} target="/roles" title="Roles" colour="red" />
				<MenuItem image={warning} target="/threats" title="Critical Actions" colour="red" />
				<MenuItem image={marker} target="/map" title="Maps" colour="green" enabled={mapEnabled} />
				<MenuItem image={info} target="/info" title="Info" colour="green" />
			</div>
			<div style={{ flexGrow: 1 }}></div>
			{userType === 'admin' && (
				<a className="button--admin" href="/admin/menu">
					Go to admin console
				</a>
			)}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<MenuProps> => ({
	incident: state.activeIncident || null,
	content: state.content,
	orgId: state?.login?.organisationid,
	userType: state?.login?.usertype,
})
const actions = {
	goToPage: goToPage,
}
export default connect(mapStateToProps, actions)(Menu)
