import { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'

import { StateTree } from './types'

import { fetchContent } from './actions/misc'

// @ts-ignore
const CONTENT_BASE_URL = process.env.CONTENT_BASE_URL

type ContentCacheProps = {
	orgId: string
	fetchContent: () => Promise<any>
}

const images = []
function preload(imageUrls: string[]) {
	for (let i = 0; i < imageUrls.length; i++) {
		images[i] = new Image()
		images[i].src = imageUrls[i]
	}
}

// Non-visual component that fetches content for the app (critical action pages, info, group names, maps, etc.) and
// preloads all images. The service worker will cache these images in case the app is used offline later.
const ContentCache: FunctionComponent<ContentCacheProps> = props => {
	const { orgId, fetchContent } = props

	const fetchContentForOrganisation = async (orgId: string) => {
		const content = await fetchContent()
		preload((content?.media || []).map(m => CONTENT_BASE_URL + orgId + '/' + m.key))
	}

	// Fetch content when organisation ID exists/changes
	useEffect(() => {
		if (orgId) fetchContentForOrganisation(orgId)
	}, [orgId])

	return null
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return { orgId: state?.login?.schoolid }
}
const actions = {
	fetchContent: fetchContent,
}
export default connect(mapStateToProps, actions)(ContentCache)
