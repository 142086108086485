import React, { FunctionComponent } from 'react'

type ScrollerProps = {
	className?: string
	children?: any
}

const Scroller = React.forwardRef<HTMLDivElement, ScrollerProps>((props, ref) => {
	const { className } = props
	return (
		<div ref={ref} className={'scroller' + (className ? ' ' + className : '')}>
			{props.children}
		</div>
	)
})

export default Scroller
