import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { Incident, Organisation, StateTree } from '../../../types'

import { getIncidents, selectIncident, deleteIncident } from '../../../actions/incidents'
import PreloaderSection from '../../modules/PreloaderSection'
import IncidentPopup from '../../popups/IncidentPopup'
import Page from '../../elements/Page'
import { useNavigate } from 'react-router-dom'
import OrganisationSelector from '../../modules/OrganisationSelector'

type IncidentsProps = {
	getIncidents: (orgId?: string) => Promise<any>
	incidents: Incident[]
	selectedIncident?: Incident
	selectIncident: (incident: Incident) => void
	deleteIncident: (incident: Incident) => void
	selectedOrganisation?: Organisation
}

const Incidents: FunctionComponent<IncidentsProps> = props => {
	const { getIncidents, incidents, selectedIncident, selectIncident, deleteIncident, selectedOrganisation } = props

	const navigate = useNavigate()

	const viewLogs = (incident: Incident) => {
		navigate('/admin/logs/' + incident.id)
	}

	const orgId = selectedOrganisation?.id
	return (
		<Page title="Incidents" showBackButton={false}>
			<OrganisationSelector />
			<PreloaderSection reloadkey={orgId} dataloader={() => getIncidents(orgId)}>
				<table>
					<thead>
						<tr>
							<th>Date/time</th>
							<th>Details</th>
							<th>Status</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{incidents.map(incident => (
							<tr key={incident.id}>
								<td>{incident.incidentReportDateTime}</td>
								<td>{incident.incidentDetails}</td>
								<td>{incident.closed ? 'Closed' : 'Active'}</td>
								<td>
									<a href="#" onClick={() => selectIncident(incident)}>
										Edit
									</a>
									<a href="#" onClick={() => deleteIncident(incident)}>
										Delete
									</a>
									<a href="#" onClick={() => viewLogs(incident)}>
										View logs
									</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</PreloaderSection>
			{selectedIncident && <IncidentPopup />}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return {
		incidents: state.incidents || [],
		selectedIncident: state.selectedIncident,
		selectedOrganisation: state.selectedOrganisation,
	}
}
const actions = {
	getIncidents: getIncidents,
	selectIncident: selectIncident,
	deleteIncident: deleteIncident,
}
export default connect(mapStateToProps, actions)(Incidents)
