import React, { FunctionComponent } from 'react'

type RowProps = {
	className?: string
}

const Row: FunctionComponent<RowProps> = props => {
	const { className } = props
	return <div className={'row ' + (className || '')}>{props.children}</div>
}

export default Row
