import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateRoleAllocations, updateRoles } from '../../../actions/roles'
import { Role, StateTree, User, RoleAllocation } from '../../../types'
import { alert } from '../../../util/alerts'
import Button from '../../elements/Button'
import ListItem from '../../elements/ListItem'
import Page from '../../elements/Page'
import Scroller from '../../elements/Scroller'

type RoleAllocationProps = {
	users: User[]
	roles: Role[]
	roleAllocations: RoleAllocation[]
	updateRoleAllocations: (allocations: RoleAllocation[]) => void
	online: boolean
}

const RoleAllocation: FunctionComponent<RoleAllocationProps> = props => {
	const { roles, users, updateRoleAllocations, roleAllocations, online } = props
	const navigate = useNavigate()
	const [allocations, setAllocations] = useState<RoleAllocation[]>(roleAllocations)

	const onSubmit = async () => {
		await updateRoleAllocations(allocations)
		alert({ title: 'Role allocations updated' })
		navigate(-2)
	}

	const onUserSelect = (roleId, index, userId) => {
		// Remove the role allocation with index by role Id (e.g. the third allocation to role x)
		let i = 0
		let count = -1
		const newAllocations = [...allocations]

		while (count !== index) {
			i++
			if (i >= allocations.length) break
			if (allocations[i].roleid === roleId) count += 1
		}

		if (count === index) {
			if (!userId) {
				// Remove element i from allocations array
				newAllocations.splice(i, 1)
				setAllocations(newAllocations)
			} else {
				newAllocations[i].userid = userId
				newAllocations[i].id = userId + '__' + roleId
			}
		} else if (userId) {
			newAllocations.push({ schoolid: '', roleid: roleId, userid: userId, id: userId + '__' + roleId })
		}
		setAllocations(newAllocations)
	}

	return (
		<Page className="role-allocation" title="Role allocation" showBackButton={false}>
			<Scroller>
				<p>
					Please allocate the following roles to the appropriate members of your staff. If the appropriate user is not
					listed, please contact your system administrator to add them to the system.
				</p>
				{roles.map(r => {
					const selectedUsers = allocations.filter(a => a.roleid === r.id)
					return (
						<ListItem icon={r.icon || 'clipboard-list'} title={r.title}>
							{selectedUsers.map((alloc, i) => (
								<UserSelect
									key={r.id + '_' + i}
									users={users}
									value={alloc.userid}
									onChange={userid => onUserSelect(r.id, i, userid)}
									showBlankOption
									enabled={online}
								/>
							))}
							{online && (
								<UserSelect
									key={r.id + '_' + selectedUsers.length}
									users={users}
									value=""
									onChange={userid => onUserSelect(r.id, selectedUsers.length, userid)}
									showBlankOption
								/>
							)}
						</ListItem>
					)
				})}
			</Scroller>
			{online && (
				<footer>
					<Button onClick={() => navigate(-1)}>Cancel</Button>
					<Button onClick={onSubmit}>Save</Button>
				</footer>
			)}
		</Page>
	)
}

type UserSelectProps = {
	users: User[]
	onChange: (selected: string) => void
	value: string
	showBlankOption?: boolean
	enabled?: boolean
}

const UserSelect: FunctionComponent<UserSelectProps> = ({
	users,
	value,
	onChange,
	showBlankOption,
	enabled = true,
}) => {
	return (
		<select value={value} onChange={e => onChange(e.target.value)} disabled={!enabled}>
			{showBlankOption && <option value=""></option>}
			{users.map(u => (
				<option value={u.id}>{u.name}</option>
			))}
		</select>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	roles: state.roles,
	users: state.users,
	roleAllocations: state.role_allocations,
	online: state.online,
})
const actions = {
	updateRoles: updateRoles,
	updateRoleAllocations: updateRoleAllocations,
}
export default connect(mapStateToProps, actions)(RoleAllocation)
