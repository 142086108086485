import React, { FunctionComponent, useEffect, useRef } from 'react'
import Icon from './Icon'

export type ActionCardPanelDefinition = {
	title: string
	content: string
	icon?: string
	colour?: string
	textColour?: string
}

export type ActionCardDefinition = {
	backgroundColour1?: string
	backgroundColour2?: string
	textColour1?: string
	textColour2?: string
	numbered?: boolean
	panels: ActionCardPanelDefinition[]
}

type ActionCardProps = {
	definition: ActionCardDefinition
}

type ActionCardPanelProps = {
	definition: ActionCardPanelDefinition
	number?: number
}

const ActionCard: FunctionComponent<ActionCardProps> = props => {
	const { definition } = props
	const { numbered, backgroundColour1 } = definition
	const { panels = [] } = definition
	let className = 'actioncard'
	if (numbered) className += ' actioncard--numbered'
	const elem = useRef(null)
	useEffect(() => {
		if (backgroundColour1) {
			elem.current.style.setProperty('--background-colour-1', backgroundColour1)
		}
	}, [])
	return (
		<div ref={elem} className={className}>
			{panels.map((p, i) => {
				return <ActionCardPanel definition={p} number={i + 1} />
			})}
		</div>
	)
}

const ActionCardPanel: FunctionComponent<ActionCardPanelProps> = props => {
	const { number, definition } = props
	const { title, content, icon, colour, textColour } = definition
	const style = {}
	if (colour) style['backgroundColor'] = colour
	if (textColour) style['color'] = textColour
	return (
		<div className="actioncard-panel" style={style}>
			<div className="actioncard-panel__number">{number}</div>
			{title && <div className="actioncard-panel__title">{title}</div>}
			{icon && <Icon name={icon}></Icon>}
			{content && <p className="actioncard-panel__content">{content}</p>}
		</div>
	)
}

export default ActionCard
