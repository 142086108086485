import React, { FunctionComponent } from 'react'
import { matchPath, Route, Routes as RouterRoutes, useLocation, useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'

import Map from './pages/Map'
import Menu from './pages/Menu'
import Logs from './pages/Logs'
import Login from './pages/Login'
import Roles from './pages/roles/Roles'
import Threats from './pages/Threats'
import Info from './pages/Information'
import Settings from './pages/Settings'
import Checklist from './pages/Checklist'
import SchoolTrip from './pages/SchoolTrip'
import RoleActions from './pages/roles/RoleActions'
import Contacts from './pages/contacts/Contacts'
import RoleAllocation from './pages/roles/RoleAllocation'
import InitialAction from './pages/info/InitialAction'
import IncidentNew from './pages/incidents/IncidentNew'
import IncidentEdit from './pages/incidents/IncidentEdit'
import ContactGroups from './pages/contacts/ContactGroups'
import IncidentReports from './pages/incidents/IncidentReports'
import IncidentDetails from './pages/incidents/IncidentDetails'

import { LoginDetails, StateTree } from '../types'
import { connect } from 'react-redux'
import LiveIncidentContacts from './pages/contacts/LiveIncidentContacts'
import AddIncidentContact from './pages/contacts/AddIncidentContact'
import ThreatContent from './pages/ThreatContent'
import InformationContent from './pages/InformationContent'
import { checkLogin } from '../actions/login'
import { useMount } from 'react-use'

type RoutesProps = {
	login: LoginDetails
	checkLogin: () => void
}

export const PageDepths = {
	'/': 0,
	'/menu': 1,
	'/map': 2,
	'/roles': 2,
	'/contacts': 2,
	'/settings': 2,
	'/school-trip': 2,
	'/logs': 10,
	'/threats': 2,
	'/info': 2,
	'/incidents': 6,
	'/contacts/:groupid': 3,
	'/roles/:roleid': 3,
	'/checklist/:roleid': 4,
	'/initial-action': 4,
	'/incidents/:incidentid': 7,
	'/incidents/:incidentid/edit': 8,
	'/incidents/live': 8,
	'/info/:id': 6,
	'/roles-allocation': 6,
	'/threats/:path': 4,
	'/threats/:path/:path': 5,
	'/threats/:path/:path/:path': 6,
}

// This utility function can be used in other components to check the "page depth" of any particular
// route. We perform this lookup by matching the given pathname against our known routes. e.g. if
// the pathname is /contacts/1 then it will match with the generic path /contacts/:groupid
export const getRouteDepth = (path: string) => {
	let route = Object.keys(PageDepths).find(key => matchPath(key, path || ''))
	const depth = PageDepths[route] || 0
	return depth
}

const Routes: FunctionComponent<RoutesProps> = props => {
	const location = useLocation()
	const navigate = useNavigate()

	const { checkLogin } = props
	useMount(() => checkLogin())

	return (
		<AnimatePresence>
			<RouterRoutes location={location} key={location.pathname}>
				<Route path="/" element={<Login />} />
				<Route path="/menu" element={<Menu />} />
				<Route path="/contacts" element={<ContactGroups />} />
				<Route path="/settings" element={<Settings />} />
				<Route path="/incidents" element={<IncidentReports />} />
				<Route path="/school-trip" element={<SchoolTrip />} />
				<Route path="/logs" element={<Logs />} />
				<Route path="/map" element={<Map />} />
				<Route path="/roles" element={<Roles />} />
				<Route path="/roles-allocation" element={<RoleAllocation />} />
				<Route path="/contacts/live" element={<LiveIncidentContacts />} />
				<Route path="/contacts/live/add" element={<AddIncidentContact />} />
				<Route path="/contacts/:groupid" element={<Contacts />} />
				<Route path="/roles/:roleid" element={<RoleActions />} />
				<Route path="/checklist/:roleid" element={<Checklist />} />
				<Route path="/initial-action" element={<InitialAction />} />
				<Route path="/incidents/:incidentid" element={<IncidentDetails />} />
				<Route path="/incidents/:incidentid/edit" element={<IncidentEdit />} />
				<Route path="/incidents/new" element={<IncidentNew />} />
				{/* <Route path="/info/websites" element={<Websites />} />
				<Route path="/info/office" element={<OfficeContact />} />
				<Route path="/info/school" element={<SchoolInfo />} /> */}
				<Route path="/info" element={<Info />} />
				<Route path="/info/*" element={<InformationContent />} />

				<Route path="/threats" element={<Threats />} />
				<Route path="/threats/*" element={<ThreatContent />} />
				{/* <Route path="/threats/fire" element={<FireProcedure />} />
				<Route path="/threats/bomb" element={<BombThreat />} />
				<Route path="/threats/chemicalspill" element={<ChemicalSpill />} />
				<Route path="/threats/fire/evacuation" element={<FireProcedure_Evacuation />} />
				<Route path="/threats/fire/discovery" element={<FireProcedure_Discovery />} />
				<Route path="/threats/fire/followup" element={<FireProcedure_FollowUp />} />
				<Route path="/threats/fire/responsibilities" element={<FireProcedure_Responsibilties />} />
				<Route path="/threats/suspiciouspackage" element={<SuspiciousPackage />} />
				<Route path="/threats/suspiciouspackage/recognising" element={<SuspiciousPackage_Recognising />} />
				<Route path="/threats/suspiciouspackage/checklist" element={<SuspiciousPackage_Checklist />} /> */}
			</RouterRoutes>
		</AnimatePresence>
	)
}

// export default Routes

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	login: state.login || null,
})
const actions = {
	checkLogin: checkLogin,
}
export default connect(mapStateToProps, actions)(Routes)
