import React, { FunctionComponent } from 'react'
import Row from './Row'

type ValueRowProps = {
	label: string
	value: string
}

const ValueRow: FunctionComponent<ValueRowProps> = props => {
	const { value, label } = props
	return (
		<Row className="value-row">
			<div className="value-row__label">{label}</div>
			<div className="value-row__value">{value}</div>
		</Row>
	)
}

export default ValueRow
