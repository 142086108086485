import React, { FunctionComponent, useRef } from 'react'
import Preloader from './PreloaderBricks'
import PreloaderOverlay from './PreloaderOverlay'

type PopupProps = {
	className?: string
	onClose?: () => void
	onSave?: () => void
	loading?: boolean
}

const Popup: FunctionComponent<PopupProps> = props => {
	const { children, className, onClose, onSave, loading } = props
	const ref = useRef(null)
	const onClickOverlay = (e: React.MouseEvent<HTMLDivElement>) => {
		// I've commented this out for now as annoyingly it triggers if you click and drag inside the popup but let go outside of the popup
		// if (e.target === ref.current) {
		// 	onClose && onClose()
		// }
	}

	return (
		<div ref={ref} className="popup__overlay" onClick={onClickOverlay}>
			<div className={'popup' + (className ? ' ' + className : '')}>
				{loading ? <PreloaderOverlay /> : null}
				<div className="popup__content">{children}</div>
				<div className="popup__footer">
					<button onClick={onClose}>Close</button>
					{onSave && <button onClick={onSave}>Save</button>}
				</div>
			</div>
		</div>
	)
}

export default Popup
