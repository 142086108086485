import { createBrowserHistory } from 'history'

const hist = createBrowserHistory()

let prevPath = null
let currentPath = null

export const getPreviousPath = () => prevPath

hist.listen(({ location, action }) => {
	if (currentPath !== location.pathname) {
		prevPath = currentPath
		currentPath = location.pathname
	}
})
export default hist
