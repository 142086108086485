import { Contact, ContactGroup, Incident, Log, Organisation, Role, RoleAllocation, User, UserType } from '../types'

import parse from 'try-json'
import * as jose from 'jose'

const rootHost = 'https://k30n6cup6l.execute-api.eu-west-2.amazonaws.com/live'

let token = localStorage.getItem('authtoken') || null

export type LoginResult = {
	schoolid?: string
	success?: boolean
	token?: string
	error?: string
	type?: UserType
	organisationid?: string
}

// -------------------------------------------------------------------------------------------------

// https://k30n6cup6l.execute-api.eu-west-2.amazonaws.com/live/incidents
async function fetchJson(endpoint, options) {
	const response = await fetch(rootHost + endpoint, {
		...options,
		headers: {
			...(options || {}).headers,
			'Content-Type': 'application/json',
			Authorization: token ? 'Bearer ' + token : null,
		},
	})
	const json = await response.json()
	if (!response.ok) {
		json.errorStatus = response.status
		if (json.errorStatus === 500) json.message = 'Sorry, we had a problem with that. Please try again.'
	}
	return json
}

async function POST(endpoint, body) {
	const options = { method: 'POST', body: JSON.stringify(body) }
	return fetchJson(endpoint, options)
}

async function DELETE(endpoint, body = {}) {
	const options = { method: 'DELETE', body: JSON.stringify(body) }
	return fetchJson(endpoint, options)
}

async function GET(endpoint) {
	const options = { method: 'GET' }
	return fetchJson(endpoint, options)
}

// -------------------------------------------------------------------------------------------------

export async function login(username: string, password: string, type: string): Promise<LoginResult> {
	const res = await POST('/login', { username, password, type })
	token = res?.token
	localStorage.setItem('authtoken', token)
	return res
}

const asArray = obj => (Array.isArray(obj) ? obj : [])

// -------------------------------------------------------------------------------------------------

// Add organisationId as query path parameter if present
function withOrg(url, orgId) {
	return orgId ? `${url}?org=${orgId}` : url
}

export async function submitEmail(email: string): Promise<void> {
	return await POST('/reset/email', { email })
}

export async function submitNewPassword(resetkey: string, password: string): Promise<void> {
	return await POST('/reset/password', { resetkey, password })
}

export async function getLogs(incidentId: string) {
	return asArray(await GET(`/incidents/${incidentId}/logs`)) as Log[]
}

export async function addLog(incidentId: string, log: Log) {
	return POST(`/incidents/${incidentId}/logs`, log)
}

export async function getIncidents(orgId?: string) {
	return asArray(await GET(withOrg(`/incidents`, orgId))) as Incident[]
}

export async function addIncident(incident: Incident) {
	return POST(`/incidents`, incident)
}

export async function updateIncident(incidentId: string, incident: Incident) {
	return POST(`/incidents/${incidentId}`, incident)
}
export async function deleteIncident(incidentId: string) {
	return DELETE(`/incidents/${incidentId}`, null)
}

export async function updateRoleAllocations(allocations: RoleAllocation[]) {
	return POST(`/allocations`, allocations)
}

export async function getRoleAllocations(): Promise<RoleAllocation[]> {
	return asArray(await GET(`/allocations`)) as RoleAllocation[]
}

export async function getContacts(orgId?: string): Promise<Contact[]> {
	return asArray(await GET(withOrg(`/contacts`, orgId))) as Contact[]
}

export async function getContactGroups(orgId?: string): Promise<ContactGroup[]> {
	return asArray(await GET(withOrg(`/contactgroups`, orgId))) as ContactGroup[]
}

export async function updateContact(contact: Contact, orgId?: string): Promise<void> {
	return (await POST(withOrg(`/contacts/${contact.id || contact.key}`, orgId), contact)) as void
}

export async function deleteContact(contact: Contact, orgId?: string): Promise<void> {
	return DELETE(withOrg(`/contacts/${contact.id || contact.key}`, orgId), contact)
}

export async function updateContactGroup(group: ContactGroup, orgId?: string): Promise<void> {
	return (await POST(withOrg(`/contactgroups/${group.id || group.key}`, orgId), group)) as void
}

export async function deleteContactGroup(group: ContactGroup, orgId?: string): Promise<void> {
	return (await DELETE(withOrg(`/contactgroups/${group.id || group.key}`, orgId), group)) as void
}

export async function getUsers(orgId?: string): Promise<User[]> {
	return asArray(await GET(withOrg(`/users`, orgId))) as User[]
}

export async function updateUser(user: User, orgId?: string) {
	return POST(withOrg(`/users/${user.id}`, orgId), user)
}

export async function deleteUser(user: User, orgId?: string) {
	return DELETE(withOrg(`/users/${user.id || user.key}`, orgId), user)
}

export async function getContent() {
	return GET(`/content`)
}

export async function getOrganisations() {
	return GET(`/organisations`)
}

export async function updateOrganisation(org: Organisation) {
	return POST(`/organisations/${org.id}`, org)
}

export async function addOrganisation(org: Organisation) {
	return POST(`/organisations`, org)
}

export async function deleteOrganisation(org: Organisation) {
	return DELETE(`/organisations/${org.id}`)
}

export function getToken() {
	return token
}

export function getTokenPayload() {
	try {
		return token ? jose.decodeJwt(token) : null
	} catch (e) {
		return null
	}
}

export function logout() {
	token = null
	localStorage.removeItem('authtoken')
}
