import React, { FunctionComponent } from 'react'

type LinkProps = {
	onClick?: () => void
}

const Link: FunctionComponent<LinkProps> = props => {
	const { onClick, children } = props
	return (
		<a href="#" onClick={onClick}>
			{children}
		</a>
	)
}

export default Link
