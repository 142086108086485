import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { Incident, StateTree } from '../../types'
import formatDateTime from '../../util/formatDateTime'
import Icon from '../elements/Icon'
import Preloader from '../elements/PreloaderBricks'
import PreloaderSection from './PreloaderSection'
import { getIncidents } from '../../actions/incidents'
import { useMount } from 'react-use'

type ActiveIncidentBarProps = {
	incident: Incident | null
	expand?: boolean
	getIncidents?: () => Promise<void>
}

const ActiveIncidentBar: FunctionComponent<ActiveIncidentBarProps> = props => {
	const { incident, children, expand = false, getIncidents } = props

	const [expanded, setExpanded] = React.useState(expand)

	let content = null
	if (!incident) {
		content = (
			<div className="active-incident-bar active-incident-bar--inactive">
				<h3>No Active Incident</h3>
			</div>
		)
	} else {
		let className = 'active-incident-bar'
		if (expanded) {
			className += ' active-incident-bar--expanded'
		}
		content = (
			<div className={className}>
				<h3 onClick={() => setExpanded(!expanded)}>
					Active Incident
					<Icon style="solid" name={'caret-' + (expanded ? 'up' : 'down')} />
				</h3>
				<p className="active-incident-bar__datetime">Reported: {formatDateTime(new Date(incident.timestamp))}</p>
				<p className="active-incident-bar__details">{incident.incidentDetails}</p>
				{children ? <div className="active-incident-bar__children">{children}</div> : null}
			</div>
		)
	}

	// Initially we displayed a preloader when checking for an active incident, but that didn't look
	// very good. So now we just display "No active incident" unless we have successfully checked and
	// found an incident to display.
	useMount(() => getIncidents())

	// if (!incident) {
	// 	return (
	// 		<PreloaderSection className="active-incident-bar" dataloader={fetchIncidents}>
	// 			{content}
	// 		</PreloaderSection>
	// 	)
	// }

	return content
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	incident: state.activeIncident || null,
})
const actions = {
	getIncidents: getIncidents,
}
export default connect(mapStateToProps, actions)(ActiveIncidentBar)
