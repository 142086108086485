import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Contact as ContactType, ContactGroup, Incident, StateTree } from '../../../types'
import ActiveIncidentBar from '../../modules/ActiveIncidentBar'
import Button from '../../elements/Button'
import Contact from '../../elements/Contact'
import Page from '../../elements/Page'

type ContactsProps = {
	contacts: ContactType[]
	activeIncident: Incident
	online?: boolean
}

const Contacts: FunctionComponent<ContactsProps> = props => {
	const { contacts, activeIncident, online } = props
	// Get contact group ID from path parameters
	let { groupid } = useParams()
	const navigate = useNavigate()

	const _contacts = contacts.filter(contact => contact.groupid === 'incident_' + activeIncident.id)

	const onClickAddContact = () => navigate('add')

	if (!activeIncident) {
		return (
			<Page className="contacts" title="Incident Contacts" showBackButton>
				<ActiveIncidentBar />
				<p>
					This page will allow you to create and access contact details that are recorded during an incident. There is
					currently no active incident.
				</p>
			</Page>
		)
	}

	return (
		<Page className="contacts" title="Incident Contacts" showBackButton>
			<ActiveIncidentBar />
			<p>
				This screen contains contacts which have been added by crisis team that are specific to the current incident in
				progress.
			</p>
			{online && (
				<Button primary onClick={onClickAddContact}>
					Add Contact
				</Button>
			)}
			{_contacts.map(({ id, name, phone, title }) => (
				<Contact key={phone} name={name} phone={phone} title={title} colour="red" />
			))}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	contacts: state.contacts || [],
	activeIncident: state.activeIncident,
	online: state.online,
})
const actions = {}
export default connect(mapStateToProps, actions)(Contacts)
