import { Log, Reducer } from '../types'

const INITIAL_STATE = []

export const LOG_ADDED: Reducer = (state, action) => {
	const log = action.payload as Log
	const newState = { ...state }
	newState.logs = [...(state.logs || [])]

	newState.logs.push(log)

	return newState
}

export const LOGS_FETCHED: Reducer = (state, action) => {
	const logs = action.payload as Log[]
	const newState = { ...state }
	newState.logs = [...(state.logs || [])]
	logs.forEach(log => {
		if (!newState.logs.find(l => l.id === log.id)) {
			newState.logs.push(log)
		}
	})
	const activeIncidentId = state.activeIncident.id
	newState.logs = newState.logs.filter(l => l.parent === activeIncidentId || l.incidentid === activeIncidentId)
	return newState
}

const reducers = {
	LOG_ADDED,
	LOGS_FETCHED,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	if (!newState.logs) {
		newState.logs = INITIAL_STATE
	}
	return newState
}

export default switchcase
