import { Reducer, Role } from '../types'

export const ROLES_UPDATED: Reducer = (state, action) => {
	const roles = (action.payload as Partial<Role>[]) || []
	const newState = { ...state }
	newState.roles = [...(state.roles || [])]

	roles.forEach(role => {
		const existingRole = newState.roles.find(r => r.id === role.id)
		if (existingRole) Object.assign(existingRole, role)
	})

	return newState
}

export const ROLE_ALLOCATIONS_UPDATED: Reducer = (state, action) => {
	const newState = { ...state }
	newState.role_allocations = action.payload
	return newState
}

const reducers = {
	ROLES_UPDATED,
	ROLE_ALLOCATIONS_UPDATED,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
