import React, { FunctionComponent, useRef } from 'react'
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import { Form, SimpleObject, StateTree, User } from '../../types'
import { confirm } from '../../util/alerts'
import formatDateTime from '../../util/formatDateTime'
import Button from '../elements/Button'
import CheckboxList from '../elements/CheckboxList'
import Input from '../elements/Input'
import ListItem from '../elements/ListItem'
import Page from '../elements/Page'
import Scroller from '../elements/Scroller'
import Textarea from '../elements/Textarea'
import FormFromDefinition from './FormFromDefinition'

const voices = {
	normal: 'Normal',
	loud: 'Loud',
	quiet: 'Quiet',
	whispered: 'Whispered',
	clear: 'Clear',
	disguised: 'Disguised',
	wellspoken: 'Well spoken',
	poorlyspoken: 'Poorly spoken',
	deep: 'Deep',
	highpitched: 'High pitched',
	hoarse: 'Hoarse',
	nasal: 'Nasal',
	impediment: 'Impediment',
	stutter: 'Stutter',
	lisp: 'Lisp',
	slurred: 'Slurred',
	other: 'Other ',
}

type ThreatFormProps = {
	userId: string
	users: User[]
	formDefinition: Record<string, any>
}

interface Window {
	opera: string
	wafflecordova: {
		share: (data: ShareData) => void
	}
}
declare const window: Window & typeof globalThis

// const formDefinition = [
// 	{ id: 'x1', label: 'Time of call' },
// 	{ id: 'x2', label: 'Telephone number you were contacted on' },
// 	{ id: 'x3', label: 'Exact wording of the threat', type: 'textarea' },
// 	{
// 		id: 'xx',
// 		label: 'Stay calm. Being cautious, and without provoking the caller, try to ask the questions below.',
// 		type: 'message',
// 	},

// 	{ id: 'x4', label: 'Where is the bomb right now?' },
// 	{ id: 'x5', label: 'What will cause it to explode?' },
// 	{ id: 'x6', label: 'When will it explode?' },
// 	{ id: 'x7', label: 'Did you place the bomb? If so, why?' },
// 	{ id: 'x8', label: 'What does it look like?' },
// 	{ id: 'x9', label: 'What is your name?' },
// 	{ id: 'x10', label: 'What kind of bomb is it?' },
// 	{ id: 'x11', label: 'What is your telephone number?' },
// 	{ id: 'x12', label: 'What is your address?', type: 'textarea' },
// 	{ id: 'x14', label: 'Time the call ended' },
// 	{ id: 'xx', label: 'Contact the Police and headteacher / nominee immediately. ', type: 'message' },
// 	{ id: 'xx', label: 'Carry out further actions based on Police advice.', type: 'message' },
// 	{ id: 'x15', label: 'What sort of voice did the caller have?', type: 'checklist', values: voices },
// ]

const ThreatForm: FunctionComponent<ThreatFormProps> = props => {
	const form = useRef<Form>({ data: JSON.parse(localStorage.getItem('form-bomb-threat') || '{}') })
	const navigate = useNavigate()

	const now = formatDateTime(new Date())
	const { userId, users, formDefinition } = props

	const onClickReset = () => {
		confirm({ text: 'Reset this form?', confirmText: 'Reset' }).then(confirmed => {
			if (!confirmed) return
			form.current.data = {}
			localStorage.removeItem('form-bomb-threat')
			// Go back - We currently don't have a way to reset the contents of the form
			setTimeout(() => navigate(-1), 100)
		})
	}

	const onClickShare = () => {
		const name = users.find(user => user.id === userId)?.name || 'Unknown'
		let shareText = `Incident360 - Bomb Threat Report\n\nForm submitted by ${name} - ${now}\n\n`

		// Add all form fields and entered values to sharetext
		const keys = Object.keys(formDefinition)
		for (const key of keys) {
			const field = formDefinition[key]
			const value = form.current.data[field.id] || ' - '

			if (field.type === 'message') continue
			if (field.type === 'checklist') {
				// If it is a checklist, create a comma separated list of selected values
				shareText += `${field.label}\n`
				shareText +=
					value
						.split(',')
						.filter(key => field.values[key])
						.map(key => field.values[key])
						.join(', ') + '\n\n'
			} else {
				shareText += `${field.label}\n${value || ' - '}\n\n`
			}
		}

		const shareData: ShareData = {
			title: 'Incident360 - Bomb Threat Report',
			text: shareText,
		}

		const cordovaShareAvailable = window?.wafflecordova?.share

		if (cordovaShareAvailable) {
			// Cordova share function (https://www.npmjs.com/package/cordova-plugin-x-socialsharing)
			window.wafflecordova.share(shareData)
		} else {
			navigator.share(shareData)
		}
	}

	const onFormChange = (data: Record<string, string>) => {
		form.current.data = data
	}

	return (
		<Page title="Bomb Threat" padding={false}>
			<FormFromDefinition definition={formDefinition} id="form-bomb-threat" onChange={onFormChange} />
			<footer>
				<Button onClick={onClickReset}>Reset</Button>
				<Button onClick={onClickShare}>Share</Button>
			</footer>
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	userId: state.login.userid,
	users: state.users,
})
const actions = {}
export default connect(mapStateToProps, actions)(ThreatForm)
