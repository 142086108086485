import React, { FunctionComponent } from 'react'
import Icon from './Icon'
import Row from './Row'

type ContactProps = {
	name?: string
	title?: string
	phone?: string
	colour?: string
	email?: string
}

const Contact: FunctionComponent<ContactProps> = props => {
	const { name, title, phone, email, colour = 'random' } = props
	return (
		<a className="contact" href={`tel:${phone}`}>
			<div className="contact__left">
				<Icon name={!phone && email ? 'envelope' : 'phone'} color={colour} />
			</div>
			<div className="contact__right">
				<p className="contact__name">{name}</p>
				<Row>
					<p className="contact__phone">{phone || email}</p>
					<p className="contact__title">{title || ''}</p>
				</Row>
			</div>
		</a>
	)
}

export default Contact
