import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../actions/login'
import { StateTree } from '../../types'
import { confirm } from '../../util/alerts'
import MenuTopBar from '../elements/MenuTopBar'
import Spacer from '../elements/Spacer'

type SideMenuProps = {
	logout: () => void
}

const SideMenu: FunctionComponent<SideMenuProps> = ({ logout }) => {
	const toggleMenu = () => {
		document.body.classList.toggle('menuopen')
	}
	const onClick = e => {
		const clickedElemClasslist = (e.target as HTMLElement).classList
		if (clickedElemClasslist.contains('spacer') || clickedElemClasslist.contains('menu-top-bar')) {
			toggleMenu()
		}
	}
	return (
		<div className="side-menu" onClick={onClick}>
			<MenuTopBar />
			<SideMenuItem target="/menu">Home</SideMenuItem>
			<SideMenuItem target="/contacts">Contacts</SideMenuItem>
			<SideMenuItem target="/incidents">Report Incident</SideMenuItem>
			<SideMenuItem target="/roles">Roles</SideMenuItem>
			<SideMenuItem target="/threats">Threats</SideMenuItem>
			<SideMenuItem target="/map">Map</SideMenuItem>
			<SideMenuItem target="/info">Info</SideMenuItem>
			<Spacer />
			{/* <SideMenuItem target="/settings">Settings</SideMenuItem> */}
			<SideMenuItem action={logout} confirmation="Are you sure you wish to log out?">
				Log out
			</SideMenuItem>
		</div>
	)
}

// export default SideMenu

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({})
const actions = {
	logout: logout,
}
export default connect(mapStateToProps, actions)(SideMenu)

// -------------------------------------------------------------------------------------------------
type SideMenuItemProps = {
	target?: string
	confirmation?: string
	action?: () => void
}

const SideMenuItem: FunctionComponent<SideMenuItemProps> = ({ children, target, confirmation, action }) => {
	const navigate = useNavigate()

	const onClick = () => {
		if (confirmation) {
			confirm({
				title: 'Confirm',
				text: confirmation,
				confirmText: 'OK',
			}).then(confirmed => {
				if (!confirmed) return
				if (target) navigate(target)
				if (action) action()
				document.body.classList.remove('menuopen')
			})
			return
		}
		navigate(target)
		document.body.classList.remove('menuopen')
	}

	return (
		<div className="side-menu-item" onClick={onClick}>
			{children}
		</div>
	)
}
