import { ActionCreator } from '../types'

export const goToPage: ActionCreator = (page: string) => async (dispatch, getState) => {
	dispatch({
		type: 'GOTO_PAGE',
		payload: { page },
	})
}

export const goBack: ActionCreator = () => async (dispatch, getState) => {
	dispatch({
		type: 'GOTO_PAGE',
		payload: { page: 'menu' },
	})
}

export default {
	goToPage,
	goBack,
}
