import React, { FunctionComponent } from 'react'
import Icon from './Icon'

type MenuTopBarProps = {
	showRefresh?: boolean
}

const MenuTopBar: FunctionComponent<MenuTopBarProps> = ({ showRefresh }) => {
	const toggleMenu = () => {
		document.body.classList.toggle('menuopen')
	}
	return (
		<div className="menu-top-bar">
			<Icon name="bars" onClick={toggleMenu} />
			{showRefresh && <Icon name="refresh" onClick={() => window.location.reload()} />}
		</div>
	)
}
export default MenuTopBar
