import React, { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import Icon from './Icon'

type MenuItemProps = {
	iconName?: string
	target?: string
	title?: string
	colour?: string
	image?: string
	enabled?: boolean
}

const MenuItem: FunctionComponent<MenuItemProps> = props => {
	const { children, title, iconName, target, colour, image, enabled } = props
	const navigate = useNavigate()
	const onClick = () => navigate(target)
	let className = 'menu-item menu-item--' + colour + ' menu-item--' + iconName
	if (enabled === false) className += ' menu-item--disabled'
	return (
		<div className={className} onClick={onClick}>
			<div className="menu-item__label">{title || children}</div>
			{iconName && <Icon className="menu-item__icon" name={iconName} />}
			{image && <img className="menu-item__icon" src={image} />}
		</div>
	)
}

export default MenuItem
