import React, { FunctionComponent, useState } from 'react'

import DateTimePicker from 'react-datetime-picker'

import { Form } from '../../types'
import formatDateTime from '../../util/formatDateTime'
import CheckboxList from '../elements/CheckboxList'
import Input from '../elements/Input'
import Textarea from '../elements/Textarea'

type IncidentFormProps = {
	form: Form
	enabled?: boolean
}

const peopleToInform = {
	headteacher: 'Headteacher',
	schoolstaff: 'School staff',
	trust: 'Trust',
	pupils: 'Pupils',
	parentscarers: 'Parents / carers',
	extendedservices: 'Extended services',
	police: 'Police',
	firerescueservice: 'Fire & Rescue Service',
	ambulanceservice: 'Ambulance Service',
	healthandsafetyexecutive: 'Health and Safety Executive',
	khda: 'KHDA',
	office: 'Office',
	media: 'Media',
	insurancecompany: 'Insurance company',
}

const IncidentForm: FunctionComponent<IncidentFormProps> = props => {
	const { enabled = true, form } = props
	const [value, onChange] = useState('')
	return (
		<div className="form incident-form">
			{/* <input type="datetime-local" value={value} onChange={e => onChange(e.target.value)} /> */}
			<Input id="incidentReportDateTime" label="Date and time of report" form={form} enabled={false} />
			<Textarea id="incidentDetails" label="Details of incident" form={form} enabled={enabled} />
			<Input id="incidentDateTime" label="Date and time of incident" form={form} enabled={enabled} />
			<Textarea id="incidentLocation" label="Exact location of incident" form={form} enabled={enabled} />

			<Input id="informant" label="Name of informant" form={form} enabled={enabled} />
			<Input id="informantDateTime" label="Date and time of notification" form={form} enabled={enabled} />
			<Input id="informantContact" label="Contact details of informant" form={form} enabled={enabled} />

			{/* <DateTimePicker onChange={onChange} value={value} /> */}

			<Textarea
				id="informantLocation"
				label="Where is the informant now and where are they going?"
				form={form}
				enabled={enabled}
			/>

			<Textarea
				id="peopleAffected"
				label="People affected (including names, injuries, where they are, where they are being taken to)"
				form={form}
				enabled={enabled}
			/>

			<Textarea
				id="arrangements"
				label="What arrangements are in place for people not directly involved in the incident?"
				form={form}
				enabled={enabled}
			/>

			<Textarea
				id="emergencyServicesAdvice"
				label="What advice have the emergency services given?"
				form={form}
				enabled={enabled}
			/>

			<CheckboxList
				id="whoInformed"
				label="Who has been informed?"
				items={Object.entries(peopleToInform).map(([key, value]) => ({ key, value }))}
				form={form}
				enabled={enabled}
			/>

			<Textarea id="notesOnInformed" label="Notes on persons/agencies informed" form={form} enabled={enabled} />
			<Textarea id="anyoneElseInformed" label="Does anyone else need to be informed?" form={form} enabled={enabled} />
			<Textarea id="actionsRequired" label="Are any other actions required?" form={form} enabled={enabled} />

			<p>
				<b>
					If the incident happened on an educational visit, please ask the questions below. You might already have these
					details, but it could be useful to seek confirmation.
				</b>
			</p>

			<Input id="visitLeader" label="Name of educational visit leader" form={form} enabled={enabled} />
			<Input id="visitPupils" label="Number of pupils on educational visit" form={form} enabled={enabled} />
			<Input id="visitNature" label="Nature of educational visit" form={form} enabled={enabled} />
			<Input id="visitStaffNumber" label="Number of staff on educational visit" form={form} enabled={enabled} />

			<Textarea id="visitLocation" label="Location of educational visit" form={form} enabled={enabled} />

			<Textarea
				id="visitFdcoRequired"
				label="If the incident happened abroad, do the Foreign, Commonwealth &amp; Development Office need to be notified?"
				form={form}
				enabled={enabled}
			/>
		</div>
	)
}

export default IncidentForm
