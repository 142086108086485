import React, { FunctionComponent, useRef, useState } from 'react'
import { Navigate, Route, Routes as RouterRoutes, useLocation, useNavigate, useParams } from 'react-router-dom'

import { Form } from '../../types'

import Button from '../elements/Button'
import Input from '../elements/Input'
import * as api from '../../util/api'
import Row from '../elements/Row'

type PasswordResetProps = {
	//
}

const PasswordReset: FunctionComponent<PasswordResetProps> = props => {
	const location = useLocation()
	return (
		<div className="password-reset">
			<RouterRoutes location={location} key={location.pathname}>
				<Route path="reset/*">
					<Route index element={<EmailForm />} />
					<Route path=":id" element={<NewPasswordForm />} />
					<Route path="thankyou" element={<EmailThankYou />} />
					<Route path="complete" element={<Complete />} />
				</Route>
			</RouterRoutes>
		</div>
	)
}

export default PasswordReset

// -------------------------------------------------------------------------------------------------

type EmailFormProps = {
	//
}

const EmailForm: FunctionComponent<EmailFormProps> = props => {
	const form = useRef<Form>({ data: {} })
	const navigate = useNavigate()

	const onSubmit = () => {
		if (!form.current.data.email) return
		api.submitEmail(form.current.data.email)
		navigate('/reset/thankyou', { replace: true })
	}

	return (
		<div className="password-email-form">
			<h1>Password reset</h1>
			<p>Please enter you email address and we will send you a link to allow you to reset your password</p>
			<Input id="email" form={form.current} label="Email address" />
			<Row>
				<Button onClick={() => navigate(-1)}>Back</Button>
				<Button onClick={onSubmit}>Submit</Button>
			</Row>
		</div>
	)
}

// -------------------------------------------------------------------------------------------------

const EmailThankYou = () => {
	const navigate = useNavigate()
	return (
		<div className="password-email-form">
			<h1>Password reset</h1>
			<p>Thank you.</p>
			<p>
				Now please check your email for a link to reset your password. It will have been sent from{' '}
				<b>noreply@incident360.app</b>
			</p>
			<Button onClick={() => navigate('/')}>Home</Button>
		</div>
	)
}

// -------------------------------------------------------------------------------------------------

const Complete = () => {
	const navigate = useNavigate()
	return (
		<div className="password-email-form">
			<h1>Password reset</h1>
			<p>Thank you.</p>
			<p>Your password has now been changed.</p>
			<Button onClick={() => navigate('/')}>Home</Button>
		</div>
	)
}

// -------------------------------------------------------------------------------------------------

type NewPasswordFormProps = {
	//
}

const NewPasswordForm: FunctionComponent<NewPasswordFormProps> = props => {
	const form = useRef<Form>({ data: {} })
	const navigate = useNavigate()

	let { id } = useParams()
	const [error, setError] = useState('')
	const onSubmit = () => {
		const { password1, password2 } = form.current.data
		if (!password1 || !password2) return setError('Please enter a password into both fields')
		if (password1 !== password2) return setError('These passwords do not match')
		// const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm
		// - at least 8 characters
		// - must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number
		// - Can contain special characters
		api.submitNewPassword(id, password1)
		navigate('/reset/complete', { replace: true })
	}
	return (
		<div className="password-email-form">
			<h1>Password reset</h1>
			<p>Please enter a new password for your account</p>
			<Input id="password1" type="password" form={form.current} label="New password" preventautofill />
			<Input id="password2" type="password" form={form.current} label="Confirm new password" preventautofill />
			{error ? <div className="error">{error}</div> : null}
			<Button onClick={onSubmit}>Submit</Button>
		</div>
	)
}
