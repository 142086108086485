import Swal, { SweetAlertIcon } from 'sweetalert2'

export async function confirm({ text = null, title = 'Confirm', confirmText = 'Confirm', cancelText = 'Cancel' } = {}) {
	const { isConfirmed } = await Swal.fire({
		title,
		text,
		showCancelButton: true,
		confirmButtonText: confirmText,
		heightAuto: false,
	})
	return isConfirmed
}

type AlertOptions = { title?: string; icon?: SweetAlertIcon }
export function alert({ title = 'Success', icon = 'success' }: AlertOptions) {
	// return Swal.fire(title, '', icon as SweetAlertIcon)
	return Swal.fire({ title, icon: icon as SweetAlertIcon, timer: 4000, timerProgressBar: true, heightAuto: false })
}
