import React, { FunctionComponent } from 'react'

type SectionBarProps = {
	//
}

const SectionBar: FunctionComponent<SectionBarProps> = props => {
	return <h3 className="section-bar">{props.children}</h3>
}

export default SectionBar
