import { ActionCreator, LoginDetails, UserType } from '../types'
import { login as loginService } from '../util/api'
import history from '../util/history'
import { logout as logoutApi, getTokenPayload } from '../util/api'
import { fetchContent } from './misc'

const redirectToMenu = () => {
	if (history.location.pathname.includes('/admin')) {
		history.replace('/admin/menu')
	} else {
		history.replace('/menu')
	}
}
const redirectToLogin = () => {
	if (history.location.pathname.includes('/admin')) {
		history.replace('/admin')
	} else {
		history.replace('/')
	}
}

export const login: ActionCreator = (userid: string, password: string, type?: string) => async (dispatch, getState) => {
	dispatch({ type: 'LOGIN_IN_PROGRESS', payload: { userid: userid } })

	let error = ''
	if (!userid || !password) {
		error = 'Username and password are required'
		return dispatch({ type: 'LOGIN_FAILED', payload: error })
	}
	let _userid = userid.toLowerCase().trim()
	const res = await loginService(_userid, password, type)

	if (res.success) {
		const loginDetails: LoginDetails = {
			userid: _userid,
			schoolid: res.schoolid,
			usertype: res.type,
			organisationid: res.organisationid,
		}
		await fetchContent()(dispatch, getState)
		dispatch({ type: 'LOGIN_SUCCESS', payload: loginDetails })
	} else {
		dispatch({ type: 'LOGIN_FAILED', payload: res.error || 'Login failed' })
	}
	if (res.success) redirectToMenu()
}

export const logout: ActionCreator = () => (dispatch, getState) => {
	logoutApi()
	redirectToLogin()
}

export const checkLogin: ActionCreator = () => async (dispatch, getState) => {
	const token = getTokenPayload()
	console.log('token', token)
	if (token && token.exp > Date.now() / 1000) {
		const loginDetails: LoginDetails = {
			userid: (token.userid || token.key) as string,
			schoolid: token.schoolid as string,
			organisationid: token.org as string,
			usertype: token.type as UserType,
		}
		console.log('loginDetails', loginDetails)
		await fetchContent()(dispatch, getState)
		dispatch({ type: 'LOGIN_SUCCESS', payload: loginDetails })
	} else {
		// Not logged in
		dispatch({ type: 'LOGIN_FAILED', payload: '' })
		console.log('HERE')
		redirectToLogin()
	}
}

export default {
	login,
}
