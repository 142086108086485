import { Reducer } from '../types'

export const reducers = {
	USERS_FETCHED: (state, action) => ({ ...state, users: action.payload }),
	USER_UPDATED: (state, action) => {
		const users = [...state.users]
		const user = action.payload
		const index = users.findIndex(u => u.id === user.id)
		if (index >= 0) users[index] = user
		else users.push(user)
		return { ...state, users }
	},
	USER_SELECTED: (state, action) => ({ ...state, selectedUser: action.payload }),
	USER_UPDATE_ERROR: (state, action) => ({ ...state, errors: [action.payload] }),
	USER_DELETED: (state, action) => ({ ...state, users: state.users.filter(u => u.id !== action.payload.id) }),
}

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
