import React, { FunctionComponent } from 'react'

import SideMenu from './components/modules/SideMenu'
import DataSync from './components/modules/DataSync'
import Routes from './components/Routes'
import ContentCache from './ContentCache'

const App: FunctionComponent = () => {
	return (
		<div className="app">
			<ContentCache />
			<DataSync />
			<Routes />
			<SideMenu />
		</div>
	)
}

export default App
