import React, { FunctionComponent } from 'react'
import Page from '../../elements/Page'

// import svg from '../../../../resources/flow-diagram.svg'
// import svg from '../../../../../resources/flow-diagram.png'
import flowchart from '../../../../img/flowchart-2.png'

import ReactMarkdown from 'react-markdown'
import Scroller from '../../elements/Scroller'

type InitialActionProps = {
	//
}

const content = `
Immediately inform the headteacher or nominated emergency contact. If neither is able to respond (the may be involved in the incident) the senior person present should follow the instructions below.
`
/*


- **Assess the situation and establish a basic overview of the incident.**
- **Take immediate action to safeguard pupils, staff and visitors.**
- **Attend to any casualties and administer first aid, if appropriate.**
- **If appropriate, dial 999 for the emergency services and provide them with an overview of the situation. If in doubt, dial 999.**

The emergency services notify each other of incidents but consider speaking directly to each organisation required. This will ensure that each service has the information they need to respond appropriately.
*/

// const content2 = `
// These contact details should only be used in an emergency. Do not give them to the media, pupils, parents / carers or members of the public.
//  - **Fetch any equipment that may prove useful (e.g. first aid kit, grab bag).**
//  - **Log all communications and actions.**
//  - **Notify school staff. Consider assembling a School Emergency Management Team (SEMT) to assist with the response.**
//  - **Refer to the list of emergency contact numbers for additional support if required.**
//  - **Where possible, avoid closing the school and try to maintain normal routines.**
// `

const InitialAction: FunctionComponent<InitialActionProps> = props => {
	return (
		<Page className="initial-action" title="Initial action" showBackButton padding={false}>
			<Scroller>
				<ReactMarkdown className="markdown">{content}</ReactMarkdown>
				<img src={flowchart} alt="Flow diagram" />
				{/* <ReactMarkdown className="markdown">{content2}</ReactMarkdown> */}
			</Scroller>
		</Page>
	)
}

export default InitialAction
