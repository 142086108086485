import React, { FunctionComponent } from 'react'
import Page from '../elements/Page'

type SchoolTripProps = {
	//
}

const SchoolTrip: FunctionComponent<SchoolTripProps> = props => {
	return <Page title="School trip" showBackButton></Page>
}

export default SchoolTrip
