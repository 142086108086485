import { motion } from 'framer-motion/dist/framer-motion'
import React, { FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'

import { getPreviousPath } from '../../util/history'
import OfflineBanner from './OfflineBanner'
import { getRouteDepth } from '../Routes'
import PageHeader from './PageHeader'

type PageProps = {
	className?: string
	title?: string
	subtitle?: string
	showBackButton?: boolean
	showMenuButton?: boolean
	header?: boolean
	padding?: boolean
	depth?: number
	offlinebanner?: boolean
	headerButton?: React.ReactNode
}

const LEFT_POSITION = { transform: 'translateX(-100%)', opacity: 1 }
const CENTRAL_POSITION = { transform: 'translateX(0%)', opacity: 1 }
const RIGHT_POSITION = { transform: 'translateX(100%)', opacity: 1 }

// Use a 300ms ease-out page transition
const TRANSITION_OPTIONS = { ease: [0.19, 1, 0.22, 1], duration: 0.4 }
// const TRANSITION_OPTIONS = { ease: [0.19, 1, 0.22, 1], duration: 4 }

const Page: FunctionComponent<PageProps> = props => {
	const {
		className,
		showBackButton = true,
		offlinebanner = true,
		showMenuButton,
		title,
		header = true,
		padding = true,
		subtitle,
		headerButton,
	} = props

	let _className = `page ${className || ''}`
	if (!padding) _className += ' page--nopadding'

	// When navigating into this page, we need to know the depth of the page that was displayed
	// previously. We can get the path of the previous page from the history.
	// const prevPageDepth = depths[getPreviousPath()] || 0
	const prevPageDepth = getRouteDepth(getPreviousPath())

	// When exiting this page, we need to check the depth of the new page. The path of the new page
	// will already be in location.pathname at this point.
	const location = useLocation()
	const nextPageDepth = getRouteDepth(location.pathname)

	const content = (
		<>
			{header ? (
				<PageHeader showBackButton={showBackButton} showMenuButton={showMenuButton} title={title} subtitle={subtitle}>
					{headerButton}
				</PageHeader>
			) : null}
			<div className="page__content">
				{props.children}
				{offlinebanner && <OfflineBanner />}
			</div>
		</>
	)

	// Don't bother with animation in 'admin' screens
	if (location.pathname.includes('/admin')) {
		return <div className={_className}>{content}</div>
	}

	return (
		<motion.div
			className={_className}
			animate={CENTRAL_POSITION}
			transition={TRANSITION_OPTIONS}
			// Animate in from the right if this page has a greater "depth" than the previous page, or vice versa.
			initial={nextPageDepth > prevPageDepth ? RIGHT_POSITION : LEFT_POSITION}
			// Animate out to the left if this page has a smaller "depth" than the next page, or vice versa.
			exit={prevPageDepth > nextPageDepth ? RIGHT_POSITION : LEFT_POSITION}
		>
			{header ? (
				<PageHeader showBackButton={showBackButton} showMenuButton={showMenuButton} title={title} subtitle={subtitle}>
					{headerButton}
				</PageHeader>
			) : null}
			<div className="page__content">{props.children}</div>
			{offlinebanner && <OfflineBanner />}
		</motion.div>
	)
}

export default Page
