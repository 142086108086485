import { v4 as uuid } from 'uuid'
import { ActionCreator, Incident, IncidentDetail } from '../types'
import { alert, confirm } from '../util/alerts'
import * as API from '../util/api'

const shortDate = (date: Date) => {
	const d = new Date(date)
	return d.toISOString().split('T')[0].split('-').join('')
}

export const createIncident: ActionCreator = (incidentDetails: IncidentDetail) => async (dispatch, getState) => {
	const incident: Incident = {
		...incidentDetails,
		id: shortDate(new Date()) + '_' + uuid(),
		timestamp: new Date().toISOString(),
	}
	dispatch({ type: 'INCIDENT_CREATED', payload: incident })
	await API.addIncident(incident)
}

export const updateIncident: ActionCreator = (incidentDetails: Incident) => async (dispatch, getState) => {
	// const state = getState()
	// const incident = state.selectedIncident
	// if (!incident) return

	// const updatedIncident = {
	// 	...incident,
	// 	...incidentDetails,
	// }

	// dispatch({ type: 'INCIDENT_UPDATED', payload: updatedIncident })
	// await API.updateIncident(incident.id, updatedIncident)
	dispatch({ type: 'INCIDENT_UPDATED', payload: incidentDetails })
	await API.updateIncident(incidentDetails.id, incidentDetails)
}

export const deleteIncident: ActionCreator = (incidentDetails: Incident) => async (dispatch, getState) => {
	confirm({ text: 'Are you sure you want to delete this incident and all logs associated with it?' }).then(
		async result => {
			if (!result) return
			await API.deleteIncident(incidentDetails.id)
			dispatch({ type: 'INCIDENT_DELETED', payload: incidentDetails })
			alert({ title: 'Incident deleted' })
		}
	)
}

export const closeIncident: ActionCreator = (id: string) => async (dispatch, getState) => {
	const state = getState()
	const incident = state.incidents.find(i => i.id === id)
	if (!incident) return

	const updatedIncident = { ...incident, closed: new Date().toISOString() }

	dispatch({ type: 'INCIDENT_UPDATED', payload: updatedIncident })
	await API.updateIncident(id, updatedIncident)
}

export const getIncidents: ActionCreator = (orgId?: string) => async dispatch => {
	let incidents = await API.getIncidents(orgId)
	dispatch({ type: 'INCIDENTS_FETCHED', payload: incidents })
}

export const selectIncident: ActionCreator = (incident: Incident) => async (dispatch, getState) => {
	dispatch({ type: 'INCIDENT_SELECTED', payload: incident ? { ...incident } : null })
}
