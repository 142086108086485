import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { getContactGroups, selectContactGroup, deleteContactGroup } from '../../../actions/contacts'
import { ContactGroup, Organisation, StateTree } from '../../../types'
import OrganisationSelector from '../../modules/OrganisationSelector'
import PreloaderSection from '../../modules/PreloaderSection'
import Button from '../../elements/Button'
import Page from '../../elements/Page'
import ContactGroupDetailsPopup, { colours } from '../../popups/ContactGroupDetailsPopup'

type ContactGroupsProps = {
	getContactGroups: (orgId?: string) => Promise<any>
	contactGroups: ContactGroup[]
	selectContactGroup: (contactGroup: ContactGroup) => void
	deleteContactGroup: (contactGroup: ContactGroup) => void
	selectedContactGroup?: ContactGroup
	selectedOrganisation?: Organisation
}

const ContactGroups: FunctionComponent<ContactGroupsProps> = props => {
	const {
		getContactGroups,
		contactGroups,
		selectContactGroup,
		selectedContactGroup,
		deleteContactGroup,
		selectedOrganisation,
	} = props

	const onClickCreate = () => selectContactGroup({} as ContactGroup)

	contactGroups.sort((a, b) => {
		const parentNameA = contactGroups.find(g => g.id === a.parentgroupid)?.name || a.parentgroupid || ''
		const parentNameB = contactGroups.find(g => g.id === b.parentgroupid)?.name || b.parentgroupid || ''
		if (parentNameA > parentNameB) return 1
		if (parentNameA < parentNameB) return -1
		if (a.name > b.name) return 1
		if (a.name < b.name) return -1
		return 0
	})
	const headerButton = <Button onClick={onClickCreate}>Create</Button>
	return (
		<Page title="Contact Groups" showBackButton={false} headerButton={headerButton}>
			<OrganisationSelector />
			<PreloaderSection
				reloadkey={selectedOrganisation?.id}
				dataloader={() => getContactGroups(selectedOrganisation?.id)}
			>
				<table>
					<thead>
						<tr>
							<th style={{ width: 160 }}>Parent group</th>
							<th style={{}}>Name</th>
							<th style={{ width: 160 }}>Colour</th>
							<th style={{ width: 160 }}>Actions</th>
						</tr>
					</thead>
					<tbody>
						{contactGroups.map(group => {
							const colour = group.colour ? colours.find(c => c.key === group.colour).value : 'Green'

							return (
								<tr key={group.id}>
									<td>{contactGroups.find(g => g.id === group.parentgroupid)?.name || group.parentgroupid || ' - '}</td>
									<td>{group.name}</td>
									<td>{colour}</td>
									<td>
										<a href="#" onClick={() => selectContactGroup(group)}>
											Edit
										</a>
										<a href="#" onClick={() => deleteContactGroup(group)}>
											Delete
										</a>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</PreloaderSection>
			{selectedContactGroup && <ContactGroupDetailsPopup />}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return {
		contactGroups: state.contact_groups || [],
		selectedContactGroup: state.selectedContactGroup,
		selectedOrganisation: state.selectedOrganisation,
	}
}
const actions = {
	getContactGroups: getContactGroups,
	selectContactGroup: selectContactGroup,
	deleteContactGroup: deleteContactGroup,
}
export default connect(mapStateToProps, actions)(ContactGroups)
