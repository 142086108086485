import React, { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'

type MarkdownLinkProps = {
	href?: string
}

const MarkdownLink: FunctionComponent<MarkdownLinkProps> = props => {
	const navigate = useNavigate()

	// If this is an internal link, use react-router-dom's navigate function
	if (props.href.startsWith('/')) {
		const onClick = e => {
			navigate(props.href)
			e.preventDefault()
		}
		return (
			<a href="" onClick={onClick}>
				{props.children}
			</a>
		)
	}
	// All other links, render as normal
	return <a href={props.href}>{props.children}</a>
}

export default MarkdownLink
