import React, { FunctionComponent } from 'react'

type CardProps = {
	title: string
	url?: string
	image?: string
}

const Card: FunctionComponent<CardProps> = props => {
	const { title, url, children, image } = props
	const tidyUrl = (url || '').replace('http://', '').replace('https://', '').replace('www.', '')
	const className = 'card' + (url ? ' card--withurl' : '')

	const onClick = () => {
		if (url) window.open(url || '', '_blank')
	}

	// prettier-ignore
	const linkElem = url ? <a href={url} target="_blank">{tidyUrl}</a> : ''

	return (
		<div className={className} onClick={onClick}>
			{image ? <img src={image} /> : ''}
			<h3>{title}</h3>
			{linkElem}
			{children}
		</div>
	)
}

export default Card
