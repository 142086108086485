import React, { FunctionComponent, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Form, StateTree, Contact, ContactGroup, Organisation } from '../../types'
import { updateContact, selectContact, getContactGroups } from '../../actions/contacts'
import Input from '../elements/Input'
import Popup from '../elements/Popup'
import Select from '../elements/Select'
import { useMount } from 'react-use'

type ContactDetailsPopupProps = {
	selectedContact: Contact
	selectedOrganisation: Organisation
	selectContact: (contact: Contact) => void
	updateContact: (contact: Contact) => Promise<any>
	contactGroups: ContactGroup[]
	getContactGroups: (orgid?: string) => Promise<any>
}

const ContactDetailsPopup: FunctionComponent<ContactDetailsPopupProps> = props => {
	const { updateContact, selectedContact, selectContact, contactGroups, getContactGroups, selectedOrganisation } = props

	const values = { ...selectedContact }
	// Initialise some default values if we're creating a new contact group
	if (Object.keys(values).length === 0) {
		values.groupid = contactGroups?.[0]?.id
	}

	const _contactGroups = selectedOrganisation
		? contactGroups.filter(group => group.parent === selectedOrganisation.id)
		: contactGroups
	const _contactGroupOptions = _contactGroups.map(group => ({ key: group.id, value: group.name }))

	const form = useRef<Form>({ data: values })

	const [loading, setLoading] = useState(true)

	useMount(async () => {
		setLoading(true)
		await getContactGroups(selectedOrganisation?.id)
		setLoading(false)
	})

	if (!form.current.data.groupid) form.current.data.groupid = _contactGroups?.[0]?.id

	useEffect(() => {
		form.current.data = selectedContact || {}
	}, [selectedContact])

	const newContact = !selectedContact.id

	if (!loading && contactGroups.length === 0)
		return (
			<Popup className="contact-details-popup" onClose={() => selectContact(null)}>
				<p> You need to create at least one contact group first.</p>
			</Popup>
		)

	const onSave = async () => {
		setLoading(true)
		try {
			await updateContact({ ...selectedContact, ...form.current.data })
			selectContact(null)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}

	return (
		<Popup className="contact-details-popup" onClose={() => selectContact(null)} onSave={onSave} loading={loading}>
			<Select id="groupid" label="Group" form={form.current} options={_contactGroupOptions} />
			<Input id="name" label="Name" form={form.current} enabled={true} />
			<Input id="phone" label="Phone number" form={form.current} enabled={true} />
			<Input id="title" label="Title (optional)" form={form.current} enabled={true} />
		</Popup>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	selectedContact: state.selectedContact,
	contactGroups: state.contact_groups || [],
	selectedOrganisation: state.selectedOrganisation,
})
const actions = {
	updateContact: updateContact,
	selectContact: selectContact,
	getContactGroups: getContactGroups,
}
export default connect(mapStateToProps, actions)(ContactDetailsPopup)
