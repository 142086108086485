import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { StateTree } from '../../types'
import Button from '../elements/Button'
import Page from '../elements/Page'

import PreloaderOverlay from '../elements/PreloaderOverlay'
// import { useMount } from 'react-use'
// import PreloaderOverlay from '../elements/PreloaderOverlay'

import languages from '../../assets/languages.json'
// @ts-ignore
import cognitasLogo from '../../../img/cognitas.png'

import { useMount } from 'react-use'
import { login, checkLogin } from '../../actions/login'
import { useNavigate } from 'react-router-dom'

type LoginProps = {
	login: (username: string, password: string, userType?: string) => void
	inprogress: boolean
	error: string
	checkLogin: () => void
	online: boolean
	userType?: string
}

const Login: FunctionComponent<LoginProps> = props => {
	const { login, inprogress, error, checkLogin, online, userType } = props
	const [username, setUsername] = React.useState('')
	const [password, setPassword] = React.useState('')
	const [language, setLanguage] = React.useState('en')

	useMount(() => checkLogin())
	const navigate = useNavigate()

	let classname = 'login'
	if (inprogress || typeof online === 'undefined') {
		classname += ' login--busy'
	}
	const langArr = Object.keys(languages).map((key: string) => ({ key, value: languages[key].name }))
	const onChangeLang = e => setLanguage(e.target.value)
	const onClickLogin = () => login(username, password, userType)

	return (
		<Page className={classname} depth={0} header={false}>
			<PreloaderOverlay />
			<div className="login__logo" />
			<div className="login__content">
				{online === true && (
					<>
						<input type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
						<input
							type="password"
							placeholder="Password"
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
						<select onChange={onChangeLang} defaultValue={language}>
							{langArr.map(({ key, value }) => (
								<option key={key} value={key}>
									{value}
								</option>
							))}
						</select>
						<Button onClick={onClickLogin}>Login</Button>
						{error && <div className="login__error">{error}</div>}
						<a className="login__forgot-link" onClick={() => navigate('/reset')}>
							forgot password?
						</a>
					</>
				)}
				{online === false && (
					<div className="login__error">
						<h2>You are offline</h2>
						<p>
							You'll need to have an internet connection to log in. Once logged in you can use the app offline for up to
							28 days.
						</p>
					</div>
				)}
			</div>
			<img className="cognitas-logo" src={cognitasLogo} />
			<p className="login__version">version 1.0</p>
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return {
		inprogress: state?.login?.inprogress || false,
		error: state?.login?.error || '',
		online: state?.online,
	}
}
const actions = {
	login: login,
	checkLogin: checkLogin,
}
export default connect(mapStateToProps, actions)(Login)
