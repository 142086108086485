import { Check, CheckItem, Reducer, StateTree } from '../types'

export const CHECKLIST_ITEM_COMPLETED: Reducer = (state, action) => {
	const check = action.payload as Check
	const newState = { ...state }
	newState.checklist_checks = [...(state.checklist_checks || [])]

	// Replace existing check if it exists
	const existingCheckIndex = newState.checklist_checks.findIndex(
		c => c.incidentid === check.incidentid && c.itemid === check.itemid && c.index === check.index
	)
	if (existingCheckIndex >= 0) {
		newState.checklist_checks[existingCheckIndex] = check
	} else {
		newState.checklist_checks.push(check)
	}

	return newState
}

export const CHECKLIST_LOCAL_LOADED: Reducer = (state, action) => {
	const checklist = action.payload as Check[]
	const newState: StateTree = { ...state, local_checklist: checklist }
	return newState
}

export const CHECKLIST_LOCAL_ITEM_COMPLETED: Reducer = (state, action) => {
	const check = action.payload as Check
	const newState = { ...state }
	newState.local_checklist = [...(state.local_checklist || [])]

	// Replace existing check if it exists
	const existingCheckIndex = newState.local_checklist.findIndex(
		c => c.incidentid === check.incidentid && c.itemid === check.itemid && c.index === check.index
	)
	if (existingCheckIndex >= 0) {
		newState.local_checklist[existingCheckIndex] = check
	} else {
		newState.local_checklist.push(check)
	}
	return newState
}

const reducers = {
	CHECKLIST_LOCAL_ITEM_COMPLETED,
	CHECKLIST_ITEM_COMPLETED,
	CHECKLIST_LOCAL_LOADED,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
