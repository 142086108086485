import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useInterval, useMount, useTimeout } from 'react-use'
import { checkOnline } from '../../actions/misc'
import { getRoleAllocations } from '../../actions/roles'
import { getUsers } from '../../actions/users'
import { getContacts, getContactGroups } from '../../actions/contacts'
import { StateTree } from '../../types'
import { getToken } from '../../util/api'

type DataSyncProps = {
	getRoleAllocations: () => void
	getContacts: () => void
	getContactGroups: () => void
	checkOnline: () => void
	getUsers: () => void
}

const DataSync: FunctionComponent<DataSyncProps> = props => {
	const { getRoleAllocations, checkOnline, getContacts, getContactGroups, getUsers } = props
	const refresh = () => {
		if (!getToken()) return
		getRoleAllocations()
		getUsers()
	}
	const slowRefresh = () => {
		if (!getToken()) return
		getContactGroups()
		getContacts()
	}

	useMount(() => {
		refresh()
		slowRefresh()
	})

	useInterval(refresh, 5000)
	useInterval(slowRefresh, 10000)

	// useInterval(checkOnline, 1000)

	return <div className="data-sync"></div>
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({})
const actions = {
	getRoleAllocations: getRoleAllocations,
	getContactGroups: getContactGroups,
	getContacts: getContacts,
	checkOnline: checkOnline,
	getUsers: getUsers,
}
export default connect(mapStateToProps, actions)(DataSync)
