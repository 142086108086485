import React, { FunctionComponent, useState } from 'react'

import { Form } from '../../types'
import Icon from './Icon'

type CheckboxListProps = {
	id: string
	label: string
	items: { key: string; value: string }[]
	form: Form
	enabled?: boolean
	onChange?: (values: string[]) => void
}

const CheckboxList: FunctionComponent<CheckboxListProps> = props => {
	const { id, items, form, label, enabled, onChange } = props

	// Get initial list of values from form data, or use empty array
	const initialValues = form?.data?.[id] ? form.data[id].split(',') : []

	const [selected, setSelected] = useState<string[]>(initialValues)

	const _onChange = (key, checked) => {
		const newSelected = checked ? [...selected, key] : selected.filter(k => k !== key)
		// Save array of selected items in state
		setSelected(newSelected)
		// Update form with a comma seperated list of selected keys
		form.data[id] = newSelected.join(',')
		if (onChange) onChange(newSelected)
	}

	const className = 'checkbox-list' + (enabled ? '' : ' checkbox-list--disabled')

	const _label = label + (label.endsWith('?') ? '' : ':')
	// const checked = selected.includes(item.key)

	return (
		<div className={className}>
			<label>{_label}</label>
			{items.map(item => {
				const checked = selected.includes(item.key)

				return (
					<div key={item.key} className="checkbox-list__item">
						{enabled ? (
							<input
								type="checkbox"
								id={item.key}
								onChange={e => _onChange(item.key, e.target.checked)}
								disabled={!enabled}
								checked={selected.includes(item.key)}
							/>
						) : checked ? (
							// <span className="checkbox-list__checked">✓</span>
							<Icon name="check-alt" />
						) : (
							// <span className="checkbox-list__unchecked">✗</span>
							<Icon name="xmark" />
						)}
						<label htmlFor={item.key}>{item.value}</label>
					</div>
				)
			})}
		</div>
	)
}

export default CheckboxList
