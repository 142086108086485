import { ActionCreator, Role, StateTree } from '../types'

import * as API from '../util/api'

import { RoleAllocation } from '../types'

export const updateRoles: ActionCreator = (roles: Partial<Role>[]) => async (dispatch, getState) => {
	dispatch({ type: 'ROLES_UPDATED', payload: roles })
}

export const getRoleAllocations: ActionCreator = () => async (dispatch, getState) => {
	const allocations = await API.getRoleAllocations()
	dispatch({ type: 'ROLE_ALLOCATIONS_UPDATED', payload: allocations })
}

export const updateRoleAllocations: ActionCreator = (allocations: RoleAllocation[]) => async (dispatch, getState) => {
	dispatch({ type: 'ROLE_ALLOCATIONS_UPDATED', payload: allocations })
	await API.updateRoleAllocations(allocations)
}

export default {
	updateRoles,
}
