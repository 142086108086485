import React, { useEffect, useState } from 'react'
import { compose, withProps, withState, withHandlers } from 'recompose'
// const FaAnchor = require('react-icons/lib/fa/anchor')
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'
import { useMount } from 'react-use'
import ValueRow from './ValueRow'
import { Location } from '../../types'
import getCurrentLocation from '../../util/getCurrentLocation'

// @ts-ignore
const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY

const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp`

type MyGoogleMapProps = {
	defaultLocation: Location
	height?: number
	getUserLocation?: boolean
}
type MyGoogleMapPropsInner = MyGoogleMapProps & {
	onMapMounted: (ref: GoogleMap) => void
	markerLocation: Location
	what3words: string
}

const WHAT3WORDS_API_KEY = '6F4RAHQ2'
const fetchWhat3Words = async (location: Location) => {
	const { lat, lng } = location
	// const response = await fetch(`https://api.what3words.com/v2/reverse?coords=${lat},${lng}&key=${WHAT3WORDS_API_KEY}`)
	const response = await fetch(
		`https://api.what3words.com/v3/convert-to-3wa?coordinates=${lat}%2C${lng}&key=${WHAT3WORDS_API_KEY}`
	)

	const json = await response.json()
	const { words } = json
	return words
}

const MyGoogleMap = compose<MyGoogleMapPropsInner, MyGoogleMapProps>(
	withProps(({ height }) => ({
		googleMapURL: googleMapURL,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `${height || 200}px` }} />,
		mapElement: <div style={{ height: `100%` }} />,
	})),
	withState('markerLocation', 'setMarkerLocation', { lat: 0, lng: 0 }),
	withHandlers(() => {
		const refs = { map: undefined }

		return {
			onMapMounted:
				({ setMarkerLocation, defaultLocation, getUserLocation = true }) =>
				async (ref: GoogleMap) => {
					if (!ref || !ref.panTo) return

					refs.map = ref

					console.log('defaultLocation', defaultLocation)
					if (defaultLocation) {
						ref.panTo(defaultLocation)
						setMarkerLocation(defaultLocation)
						return
					}
					if (!getUserLocation) return
					const userLocation = await getCurrentLocation()
					if (userLocation) {
						ref.panTo(userLocation)
						setMarkerLocation(userLocation)
					}
				},
		}
	}),
	withScriptjs,
	withGoogleMap
)((props: MyGoogleMapPropsInner) => {
	const { defaultLocation, markerLocation, onMapMounted } = props
	// useEffect(() => {
	// 	console.log('defaultLocation2', defaultLocation)
	// }, [defaultLocation])

	return (
		<GoogleMap
			defaultCenter={defaultLocation}
			ref={onMapMounted}
			zoom={19}
			defaultMapTypeId="hybrid"
			options={{
				disableDefaultUI: true,
				keyboardShortcuts: false,
				zoomControl: true,
			}}
		>
			<Marker position={markerLocation} />
		</GoogleMap>
	)
})

function Outer(props) {
	const { defaultLocation, height = 200, onLocationFound, getUserLocation = true } = props

	const [location, setLocation] = useState(defaultLocation)
	const [what3Words, setWhat3Words] = useState('checking')

	useMount(async () => {
		console.log('getUserLocation', getUserLocation)
		if (!getUserLocation) {
			setLocation(defaultLocation)
			const result = await fetchWhat3Words(defaultLocation)
			setWhat3Words(result)
			return
		}
		const userLocation = await getCurrentLocation()
		if (userLocation) {
			setLocation(userLocation)
			const result = await fetchWhat3Words(userLocation)
			setWhat3Words(result)
			if (onLocationFound) onLocationFound(userLocation)
		}
	})

	const formatLocation = (location: Location) => {
		if (!location) return ''
		const { lat, lng } = location
		return `${lat.toFixed(6)}, ${lng.toFixed(6)}`
	}
	console.log('location2', location)
	// const url = `https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&maptype=roadmap
	// &key=YOUR_API_KEY&signature=YOUR_SIGNATURE`

	// &markers=color:blue%7Clabel:S%7C40.702147,-74.015794
	// &markers=color:green%7Clabel:G%7C40.711614,-74.012318
	// &markers=color:red%7Clabel:C%7C40.718217,-73.998284

	// const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${location.lat + ',' + location.lng}
	// 	&zoom=13&size=600x300&maptype=roadmap
	// 	&markers=color:blue%7Clabel:S%7C40.702147,-74.015794
	// 	&markers=color:green%7Clabel:G%7C40.711614,-74.012318
	// 	&markers=color:red%7Clabel:C%7C40.718217,-73.998284
	// 	&key=${GOOGLE_MAPS_API_KEY}`

	return (
		<div className="google-map">
			<MyGoogleMap defaultLocation={location} height={height} getUserLocation={getUserLocation} />
			{/* <img src={staticMapUrl} /> */}
			<ValueRow label="Location" value={formatLocation(location)} />
			<ValueRow label="What3Words" value={what3Words} />
		</div>
	)
}

export default Outer
