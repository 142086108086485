import React, { FunctionComponent, useState } from 'react'

import Switch from './Switch'
import Icon from './Icon'

type DarkModeSwitchProps = {
	value?: boolean
	toggleDarkMode?: (checked: boolean) => void
}

const DarkModeSwitch: FunctionComponent<DarkModeSwitchProps> = props => {
	const { value } = props
	const [darkMode, setDarkMode] = useState(localStorage.getItem('darkmode') !== 'false')

	const toggleDarkMode = (checked: boolean) => {
		console.log('checked', checked)
		let darkMode = !checked
		localStorage.setItem('darkmode', darkMode.toString())
		document.body.classList.toggle('dark', darkMode)
		document.body.classList.toggle('light', !darkMode)
		setDarkMode(darkMode)
		// setLightMode(checked)
		// localStorage.setItem('darkmode', (!lightMode).toString())
		// document.body.classList.toggle('dark', !lightMode)
		// document.body.classList.toggle('light', lightMode)
	}

	return (
		<div className="dark-mode-switch">
			<Icon name="moon" />
			<Switch value={!darkMode} onChange={toggleDarkMode} />
			<Icon name="sun-bright" />
		</div>
	)
}
export default DarkModeSwitch
