import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

// import checklistItems from '../../archive/cmp-checklist_items.json'

import reducers from './reducers'
import { StateTree } from './types'

const initialState: StateTree = {
	page: null,
	login: {},
	incidents: [],
	logs: [],

	users: [],
	contact_groups: [],
	contacts: [],

	roles: [],
	role_allocations: [],

	checklist_sections: [],
	checklist_items: [],
	checklist_checks: [],

	role_checklist_sections: [],

	local_checklist_sections: [
		{ checklistid: 'suspicious_package', checklistsectionid: 'sus-pkg-receiving' },
		{ checklistid: 'suspicious_package', checklistsectionid: 'sus-pkg-hazardous' },
	],
}

const middleware = applyMiddleware(thunkMiddleware)
const store = createStore(reducers, initialState, middleware)

// =============================================================================

export default store
