import React, { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import random from 'random-seed'

import Icon from './Icon'

type ListItemProps = {
	icon?: string
	title: string
	target?: string
	colour?: string
	bold?: boolean
	enabled?: boolean
}

const ListItem: FunctionComponent<ListItemProps> = props => {
	const { icon, title, target, children, colour, bold, enabled = true } = props

	const navigate = useNavigate()

	const onClick = () => {
		if (target) navigate(target)
	}

	let className = 'list-item'

	let _colour = colour || 'random'
	if (_colour === 'random') {
		// Pick a random (yet consistent) colour based on the item's title
		const colours = Object.keys(ListItemColours).filter(k => k !== 'random')
		const i = random.create(title).intBetween(0, colours.length - 1)
		_colour = colours[i]
	}

	if (_colour) className += ' list-item--' + _colour

	if (bold) className += ' list-item--bold'
	if (icon) className += ' list-item--with-icon'
	if (!enabled) className += ' list-item--disabled'
	let content = null
	if (children) {
		content = typeof children === 'object' ? children : <p>{children}</p>
	}

	return (
		<div className={className} onClick={onClick}>
			{icon ? <Icon name={icon} /> : null}
			<div className="list-item__centre">
				<h2>{title}</h2>
				{content}
			</div>
			{target ? <Icon name="chevron-right" /> : null}
		</div>
	)
}

export const ListItemColours = {
	red: 'red',
	blue: 'blue',
	black: 'black',
	green: 'green',
	purple: 'purple',
	'light-blue': 'light-blue',
	random: 'random',
}

export default ListItem
