const registerServiceWorker = async () => {
	try {
		const registration = await navigator.serviceWorker.register(new URL('../../service-worker.js', import.meta.url), {
			scope: '/',
			type: 'module',
		})
		if (registration.installing) {
			console.log('Service worker installing')
		} else if (registration.waiting) {
			console.log('Service worker installed')
		} else if (registration.active) {
			console.log('Service worker active')
		}
	} catch (error) {
		console.error(`Registration failed with ${error}`)
	}
}

export default registerServiceWorker
