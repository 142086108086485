import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Error, Form, Organisation, StateTree, User, UserRecord } from '../../types'
import { updateUser, selectUser } from '../../actions/users'
import { clearErrors } from '../../actions/misc'
import Input from '../elements/Input'
import Popup from '../elements/Popup'
import { useMount } from 'react-use'
import Select from '../elements/Select'

type UserDetailsPopupProps = {
	selectedUser: User
	selectUser: (user: User) => void
	updateUser: (user: User) => Promise<any>
	clearErrors: () => void
	errors?: Error[]
	selectedOrganisation?: Organisation
}

type FormUser = {
	password?: string
	confirmpassword?: string
} & Partial<User>

const UserDetailsPopup: FunctionComponent<UserDetailsPopupProps> = props => {
	const { updateUser, selectedUser, selectUser, clearErrors, errors, selectedOrganisation } = props

	const initialFormData: FormUser = { ...(selectedUser || {}) }
	initialFormData.password = ''
	initialFormData.type = initialFormData.type || 'standard'

	const form = useRef<Form>({ data: initialFormData })

	useMount(() => clearErrors())

	useEffect(() => {
		form.current.data = { ...(selectedUser || {}) }
		form.current.data.password = ''
	}, [selectedUser])

	const newUser = !selectedUser.id

	const [loading, setLoading] = useState(false)

	const onSave = async () => {
		setLoading(true)
		try {
			const details = {}
			for (const key in form.current.data) {
				details[key.replace('new-', '')] = form.current.data[key]
			}
			const userData: User = { ...selectedUser, ...details }
			if (selectedOrganisation) userData.organisationid = selectedOrganisation.id
			await updateUser(userData)
			selectUser(null)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}
	const passwordLabel = newUser ? 'Initial password' : 'New password'

	return (
		<Popup className="user-details-popup" onClose={() => selectUser(null)} onSave={onSave} loading={loading}>
			<form autoComplete="off">
				<Input id="id" label="Username" form={form.current} enabled={newUser} preventautofill />
				<Input id="name" label="Name" form={form.current} preventautofill />
				<Input id="email" label="Email address" form={form.current} preventautofill />
				<Select
					id="type"
					label="User type"
					form={form.current}
					options={[
						{ key: 'admin', value: 'Admin' },
						{ key: 'standard', value: 'Standard' },
					]}
				/>
				<Input id="password" label={passwordLabel} form={form.current} type="password" preventautofill />

				{!newUser && (
					<p className="smalltext">
						Enter a password here to update the user's password. Leave it blank for their password to remain the same.
					</p>
				)}
				{newUser && <Input id="confirmpassword" label="Confirm initial password" form={form.current} type="password" />}
			</form>
			{errors && errors.length > 0 && (
				<div className="popup__errors">
					{errors.map((err, i) => (
						<p key={i}>{err.message}</p>
					))}
				</div>
			)}
		</Popup>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	selectedUser: state.selectedUser,
	errors: state.errors,
	selectedOrganisation: state.selectedOrganisation || {},
})
const actions = {
	updateUser: updateUser,
	selectUser: selectUser,
	clearErrors: clearErrors,
}
export default connect(mapStateToProps, actions)(UserDetailsPopup)
