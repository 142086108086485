import React, { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import { Check, CheckItem, CheckSection, Incident, RoleChecklistSection, StateTree, User } from '../../types'
import { completeChecklistItem } from '../../actions/checklists'
import ActiveIncidentBar from '../modules/ActiveIncidentBar'
import Checklist_Inner from '../modules/Checklist_Inner'
import Page from '../elements/Page'

type ChecklistProps = {
	checks: Check[]
	items: CheckItem[]
	sections: CheckSection[]
	users: User[]
	sectionByRole: RoleChecklistSection[]
	activeIncident: Incident
	completeChecklistItem: (incidentId: string, itemid: string, index?: number, val?: boolean) => void
}

const Checklist: FunctionComponent<ChecklistProps> = props => {
	const { activeIncident, sections, items, checks, users, completeChecklistItem, sectionByRole } = props
	console.log('items', items)

	const incidentId = activeIncident?.id
	if (!incidentId) {
		return (
			<Page className="checklist" title="Checklist" showBackButton>
				<ActiveIncidentBar />
			</Page>
		)
	}

	let { roleid } = useParams()
	const sectionsForRole = sectionByRole.filter(s => s.roleid === roleid).map(s => s.checklistsectionid)
	const _sections = sections.filter(s => sectionsForRole.includes(s.id))

	const onCompleteItem = (itemid: string, index?: number, val?: boolean) => {
		return completeChecklistItem(incidentId, itemid, index, val)
	}

	return (
		<Page className="checklist" title="Checklist" showBackButton>
			<ActiveIncidentBar />
			<Checklist_Inner
				sections={_sections}
				users={users}
				checks={checks}
				items={items}
				completeChecklistItem={onCompleteItem}
			/>
		</Page>
	)
}

// export default Checklist

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	sections: state.checklist_sections,
	sectionByRole: state.role_checklist_sections,
	items: state.checklist_items,
	checks: state.checklist_checks,
	users: state.users,
	activeIncident: state.activeIncident,
})
const actions = {
	completeChecklistItem: completeChecklistItem,
}
export default connect(mapStateToProps, actions)(Checklist)
