import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useInterval } from 'react-use'
import { checkOnline } from '../../actions/misc'
import { StateTree } from '../../types'

type OfflineBannerProps = {
	checkOnline: () => void
	online: boolean
}

const OfflineBanner: FunctionComponent<OfflineBannerProps> = ({ checkOnline, online }) => {
	// const OfflineBanner: FunctionComponent<OfflineBannerProps> = ({ checkOnline }) => {
	useInterval(checkOnline, 1000)

	if (online) return null
	// const online = false

	return (
		<div className="offline-banner">
			<h2>You are currently offline</h2>
			<p>Data may not be up to date, and is read-only.</p>
		</div>
	)
}

// export default OfflineBanner

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	online: state.online,
})
const actions = {
	checkOnline: checkOnline,
}
export default connect(mapStateToProps, actions)(OfflineBanner)
