import { Reducer } from '../types'

const INITIAL_STATE = { error: null, inprogress: false }

// export const INCIDENT_CREATED: Reducer = (state, action) => {
// 	return { ...state, incidents: [...(state.incidents || []), action.payload], selected_incident: action.payload }
// }

export const INCIDENT_UPDATED: Reducer = (state, action) => {
	const updatedIncident = action.payload
	let activeIncident = state.activeIncident
	if (activeIncident?.id === updatedIncident?.id) {
		activeIncident = updatedIncident
	}
	if (activeIncident && activeIncident.closed) {
		activeIncident = null
	}
	return {
		...state,
		activeIncident,
		incidents: state.incidents.map(i => (i.id === updatedIncident.id ? updatedIncident : i)),
	}
}

export const INCIDENTS_FETCHED: Reducer = (state, action) => {
	const incidents = action.payload
	// Work out what the currently selected active incident should be
	const activeIncidents = incidents.filter(i => !i.closed)
	let activeIncident = state.activeIncident
	if (activeIncident && activeIncident.closed) {
		activeIncident = null
	}
	if (!activeIncident && activeIncidents.length > 0) {
		activeIncident = activeIncidents[0]
	}
	if (activeIncidents.length === 0) {
		activeIncident = null
	}
	return {
		...state,
		incidents,
		activeIncident,
	}
}

// const reducers = {
// 	INCIDENT_CREATED,
// 	INCIDENT_UPDATED,
// 	INCIDENTS_FETCHED,
// }

export const reducers = {
	// INCIDENTS_FETCHED: (state, action) => ({ ...state, incidents: action.payload }),
	// INCIDENT_UPDATED: (state, action) => {
	// 	const incidents = [...state.incidents]
	// 	const incident = action.payload
	// 	const index = incidents.findIndex(u => u.id === incident.id)
	// 	if (index >= 0) incidents[index] = incident
	// 	else incidents.push(incident)
	// 	return { ...state, incidents }
	// },
	INCIDENTS_FETCHED,
	INCIDENT_UPDATED,
	INCIDENT_SELECTED: (state, action) => ({ ...state, selectedIncident: action.payload }),
	INCIDENT_DELETED: (state, action) => ({
		...state,
		incidents: state.incidents.filter(u => u.id !== action.payload.id),
	}),
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	if (!newState.login) {
		newState.login = INITIAL_STATE
	}
	return newState
}

export default switchcase
