import React, { FunctionComponent, forwardRef, useImperativeHandle, useRef } from 'react'
import Scroller from '../elements/Scroller'
import { Form } from '../../types'
import CheckboxList from '../elements/CheckboxList'
import Input from '../elements/Input'
import Textarea from '../elements/Textarea'

type FormFromDefinitionProps = {
	id: string
	definition: Record<string, any>
	onChange?: (data: Record<string, any>) => void
	enabled?: boolean
	form?: Form
}

const FormFromDefinition: FunctionComponent<FormFromDefinitionProps> = forwardRef((props, ref) => {
	const { definition, id, onChange, enabled: formEnabled, form } = props
	// const form = useRef<Form>({ data: JSON.parse(localStorage.getItem(id) || '{}') })

	// Add a public reset method to this component
	useImperativeHandle(ref, () => ({
		reset() {
			/* TODO */
		},
	}))

	const _onChange = () => {
		localStorage.setItem(id, JSON.stringify(form.data))
		if (onChange) onChange(form.data)
	}

	return (
		<Scroller>
			{definition.map(({ id, label, type, values, enabled: fieldEnabled }, index) => {
				const enabled = formEnabled !== false && fieldEnabled !== false
				const fieldId = id || 'field' + index
				if (type === 'textarea')
					return <Textarea id={fieldId} label={label} form={form} onChange={_onChange} enabled={enabled} />
				// prettier-ignore
				if (type === 'message') return ( <p><b>{label}</b></p> )
				if (type === 'checklist') {
					// Convert our object of values to an array of { key: '', value:'' } objects
					const vals = values as Record<string, string>
					const items = Object.entries(vals).map(([key, value]) => ({ key, value }))
					return (
						<CheckboxList id={fieldId} label={label} items={items} form={form} onChange={_onChange} enabled={enabled} />
					)
				}
				return <Input id={fieldId} label={label} form={form} onChange={_onChange} enabled={enabled} />
			})}
		</Scroller>
	)
})

export default FormFromDefinition
