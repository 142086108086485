import React, { FunctionComponent } from 'react'
import { Check, CheckItem, CheckSection, Incident, RoleChecklistSection, StateTree, User } from '../../types'
import { confirm } from '../../util/alerts'
import SectionBar from '../elements/SectionBar'
import ChecklistItem from './ChecklistItem'

type Checklist_InnerProps = {
	completeChecklistItem: (id: string, index?: number, val?: boolean) => void
	sections: CheckSection[]
	items: CheckItem[]
	checks: Check[]
	users: User[]
}

const Checklist_Inner: FunctionComponent<Checklist_InnerProps> = props => {
	const { completeChecklistItem, sections, items, checks, users } = props

	const elems = sections.map(s => {
		const checkItemsForSection = items.filter(i => i.sectionid === s.id)
		const checkElems = checkItemsForSection.map(i => {
			const checksForThisItem = checks.filter(c => c.itemid === i.id) || undefined
			const primaryCheck = checksForThisItem.find(c => !c.index && c.index !== 0)
			const user = primaryCheck ? users.find(u => u.id === primaryCheck.userid) : undefined
			const onComplete = () => {
				confirm({ text: 'Item completed?', confirmText: 'Complete' }).then(confirmed => {
					if (!confirmed) return
					completeChecklistItem(i.id)
				})
			}
			const onDismiss = () => {
				confirm({ text: 'Mark item as not applicable?', confirmText: 'Not applicable' }).then(confirmed => {
					if (!confirmed) return
					const checked = false
					completeChecklistItem(i.id, undefined, checked)
				})
			}

			const onMarkSubitem = (index, val) => {
				completeChecklistItem(i.id, index, val)
			}
			return (
				<ChecklistItem
					key={i.id}
					checkItem={i}
					checks={checksForThisItem}
					user={user}
					onComplete={onComplete}
					onDismiss={onDismiss}
					onMarkSubitem={onMarkSubitem}
				/>
			)
		})
		return (
			<div className="checklist-section" key={s.id}>
				<SectionBar>{s.name}</SectionBar>
				{checkElems}
			</div>
		)
	})

	return <>{elems}</>
}

export default Checklist_Inner
