import { v4 as uuid } from 'uuid'
import { ActionCreator, Check, Log } from '../types'
import countListItemsInMarkdownString from '../util/countListItemsInMarkdownString'

export const completeChecklistItem: ActionCreator =
	(incidentid: string, itemid: string, index?: number, val?: boolean) => async (dispatch, getState) => {
		const state = getState()
		const userid = state.login.userid
		const checked = val === false ? false : true
		const check: Check = { incidentid, itemid, userid, index, checked, timestamp: new Date().toISOString() }
		dispatch({ type: 'CHECKLIST_ITEM_COMPLETED', payload: check })
	}

export const dismissChecklistItem: ActionCreator =
	(incidentid: string, itemid: string, index?: number, val?: boolean) => async (dispatch, getState) => {
		const state = getState()
		const userid = state.login.userid
		const checked = val === false ? false : true
		const check: Check = { incidentid, itemid, userid, index, checked, timestamp: new Date().toISOString() }
		console.log('check', check)
		dispatch({ type: 'CHECKLIST_ITEM_DISMISSED', payload: check })
	}

export const completeLocalChecklistItem: ActionCreator =
	(checklistId: string, itemid: string, index?: number, val?: boolean) => async (dispatch, getState) => {
		console.log('completeLocalChecklistItem', checklistId, itemid)
		const state = getState()
		const checklist = state.local_checklist
		console.log('checklist', checklist)
		const existingCheck = checklist.find(c => c.itemid === itemid && c.index === index)
		if (!existingCheck) {
			const userid = state.login.userid
			const checked = val === false ? false : true
			const check: Check = { itemid, userid, index, checked, timestamp: new Date().toISOString() }
			checklist.push(check)
			localStorage.setItem('local_cl_' + checklistId, JSON.stringify(checklist))
			dispatch({ type: 'CHECKLIST_LOCAL_ITEM_COMPLETED', payload: check })
		}
	}

let cache_checklist = null
let cache_checklistid = null

export const loadLocalChecklist: ActionCreator = (checklistId: string) => dispatch => {
	// Load checklist from localstorage
	if (!cache_checklist || cache_checklistid !== checklistId) {
		const storage = localStorage.getItem('local_cl_' + checklistId)
		if (storage) cache_checklist = JSON.parse(storage)
		else cache_checklist = []
		cache_checklistid = checklistId
	}
	dispatch({ type: 'CHECKLIST_LOCAL_LOADED', payload: cache_checklist })
	return cache_checklist
}

export const resetChecklist: ActionCreator = (checklistId: string) => dispatch => {
	cache_checklist = []
	cache_checklistid = checklistId
	localStorage.removeItem('local_cl_' + checklistId)
	dispatch({ type: 'CHECKLIST_LOCAL_LOADED', payload: cache_checklist })
}

// export default {
// 	completeChecklistItem,
// 	loadLocalChecklist,
// }
