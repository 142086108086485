import React, { FunctionComponent, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Check, CheckItem, User } from '../../types'
import countListItemsInMarkdownString from '../../util/countListItemsInMarkdownString'
import Icon from '../elements/Icon'

type ChecklistItemProps = {
	checkItem: CheckItem
	checks: Check[]
	user?: User
	onComplete?: () => void
	onDismiss?: () => void
	onMarkSubitem?: (index: number, val: boolean) => void
}

function formatTimestamp(timestamp: string) {
	if (!timestamp) return ''
	const date = new Date(timestamp)
	const hours = date.getHours().toString().padStart(2, '0')
	const minutes = date.getMinutes().toString().padStart(2, '0')
	return `${hours}:${minutes} - ${date.toLocaleDateString()}`
}

const ChecklistItem: FunctionComponent<ChecklistItemProps> = props => {
	const { checkItem, checks, user, onComplete, onMarkSubitem, onDismiss } = props
	const check = checks.find(c => !c.index && c.index !== 0)
	const completed = check?.checked
	const dismissed = check?.checked === false

	const [expanded, setExpanded] = React.useState(true)

	const [allowComplete, setAllowComplete] = useState(true)

	useEffect(() => {
		// declare the async data fetching function
		const check = async () => {
			const listItems = await countListItemsInMarkdownString(checkItem.content)
			const checkedListItems = checks.filter(c => c.index >= 0 && c.checked).length
			setAllowComplete(checkedListItems === listItems)
		}
		check().catch(console.error)
	})

	// If task becomes completed, minimise the task
	useEffect(() => {
		if (completed) setExpanded(false)
	}, [completed])

	let footerRightSection = null
	if (completed || dismissed) {
		footerRightSection = (
			<div>
				<div className="checklist-item__user">{user?.name || ''}</div>
				<div className="checklist-item__time">{formatTimestamp(check.timestamp)}</div>
			</div>
		)
	} else {
		footerRightSection = (
			<>
				<Icon
					name="check"
					style="solid"
					className="checklist-item__icon--complete"
					onClick={allowComplete ? onComplete : null}
				/>
			</>
		)
	}

	let status = 'Actions Incomplete'
	if (completed) status = 'Complete'
	if (dismissed) status = 'Not Applicable'

	let className = `checklist-item`
	if (completed) className += ' checklist-item--completed'
	if (dismissed) className += ' checklist-item--dismissed'
	if (expanded) className += ' checklist-item--expanded'
	if (!allowComplete) className += ' checklist-item--incomplete'

	let miniCheckIndex = -1

	// For all <li> tags, we want to render a tick/cross and allow users to check off these individual
	// items. Each one will have an index, which is where it sits within the main checklist item.
	const listItemRenderer = (x: any) => {
		const { node, className, children, ...props } = x
		miniCheckIndex++
		const val = checks.find(c => c.index === miniCheckIndex)?.checked || false
		const _className = (className || '') + (val ? ' checklist-item__mini-check--completed' : '')
		return (
			<li className={_className} {...props} onClick={onMarkSubitem.bind(this, miniCheckIndex, !val)}>
				<Icon className="minicheck" name="check" style="solid"></Icon>
				{children}
			</li>
		)
	}

	return (
		<div className={className}>
			<ReactMarkdown className="checklist-item__content" components={{ li: listItemRenderer }}>
				{checkItem.content}
			</ReactMarkdown>
			<Icon
				className="checklist-item__expander"
				name={'caret-' + (expanded ? 'up' : 'down')}
				style="solid"
				onClick={() => setExpanded(!expanded)}
			/>
			<div className="checklist-item__footer">
				<div className="checklist-item__footer-left">
					<div className="checklist-item__status">{status}</div>
				</div>
				<div className="checklist-item__footer-right">{footerRightSection}</div>
			</div>
		</div>
	)
}

export default ChecklistItem

type MiniCheckProps = {
	value: Boolean
	onChange?: (value: boolean) => void
}

const MiniCheck: FunctionComponent<MiniCheckProps> = props => {
	// const { value, onChange } = props
	const [value, setValue] = useState<Boolean>(props.value)
	return <Icon className="minicheck" name={value ? 'check' : 'xmark'} onClick={() => setValue(!value)}></Icon>
}
