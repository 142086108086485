import React, { FunctionComponent } from 'react'
import { InputProps } from './Input'

const Textarea: FunctionComponent<InputProps> = props => {
	const { id, label, initialValue = '', form, enabled = true, onChange } = props
	const [value, setValue] = React.useState(form?.data?.[id] || initialValue || '')
	const _onChange = e => {
		setValue(e.target.value)
		form.data = form.data || {}
		form.data[id] = e.target.value
		if (onChange) onChange(e.target.value)
	}
	const _label = label + (label.endsWith('?') ? '' : ':')
	return (
		<label>
			{_label}
			<textarea onChange={_onChange} value={value} disabled={!enabled}></textarea>
		</label>
	)
}

export default Textarea
