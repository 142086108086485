import React, { FunctionComponent } from 'react'

type TableLinkProps = {
	label: string
	onClick: () => void
}

const TableLink: FunctionComponent<TableLinkProps> = props => {
	const { label, onClick } = props
	return (
		<a href="#" onClick={onClick}>
			{label}
		</a>
	)
}

export default TableLink
