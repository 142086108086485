import React, { FunctionComponent, useRef } from 'react'

import IncidentForm from '../../modules/IncidentForm'
import Scroller from '../../elements/Scroller'
import Button from '../../elements/Button'
import Page from '../../elements/Page'
import { Form, Incident, IncidentDetail, StateTree } from '../../../types'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { updateIncident } from '../../../actions/incidents'
import { alert, confirm } from '../../../util/alerts'

type IncidentEditProps = {
	updateIncident: (incidentDetails: IncidentDetail) => void
	incidents: Incident[]
}

const IncidentEdit: FunctionComponent<IncidentEditProps> = props => {
	const { updateIncident, incidents } = props
	let { incidentid } = useParams()
	const incident = incidents.find(i => i.id === incidentid)
	const form = useRef<Form>({ data: incident })

	const navigate = useNavigate()

	const onSubmit = () => {
		confirm({
			title: 'Confirm',
			text: 'Please confirm that you wish to update the incident details?',
			confirmText: 'Save',
		}).then(confirmed => {
			if (!confirmed) return
			updateIncident(form.current.data as IncidentDetail)
			alert({ title: 'Incident updated' })
			navigate(-1)
		})
	}

	return (
		<Page className="incident-new" title="Update Incident" showBackButton padding={false}>
			<Scroller>
				<IncidentForm form={form.current} enabled={true} />
			</Scroller>
			<footer>
				<Button onClick={() => navigate(-1)}>Cancel</Button>
				<Button onClick={onSubmit}>Save</Button>
			</footer>
		</Page>
	)
}

// =================================================================================================

// =================================================================================================

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	incidents: state.incidents,
})
const actions = {
	updateIncident: updateIncident,
}
export default connect(mapStateToProps, actions)(IncidentEdit)
