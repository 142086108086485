import { Reducer } from '../types'

function MISC_GOT_CONTENT(state, action) {
	const {
		roles = [],
		role_checklist_sections = [],
		checklist_sections = [],
		checklist_items = [],
		...content
	} = action.payload

	return { ...state, roles, role_checklist_sections, checklist_sections, checklist_items, content }
}

const reducers = {
	ONLINE: (state, action) => ({ ...state, online: action.payload }),
	MISC_CLEAR_ERRORS: (state, action) => ({ ...state, errors: [] }),
	MISC_GOT_CONTENT,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
