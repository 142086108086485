import React, { FunctionComponent } from 'react'
import Icon from './Icon'

type ButtonProps = {
	onClick: () => void
	icon?: string
	image?: string
	className?: string
	primary?: boolean
	enabled?: boolean
}

const Button: FunctionComponent<ButtonProps> = props => {
	const { onClick, icon, image, children, enabled, primary, className } = props
	let _className = 'button'
	if (primary) _className += ' button--primary'
	if (enabled === false) _className += ' button--disabled'
	if (className) _className += ' ' + className

	return (
		<button className={_className} onClick={onClick} disabled={enabled === false}>
			{icon ? <Icon name={icon} /> : null}
			{image ? <img className="icon" src={image} /> : null}
			{children}
		</button>
	)
}

export default Button
