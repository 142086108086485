import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../actions/login'
import { StateTree } from '../../types'
import { confirm } from '../../util/alerts'
import Icon from '../elements/Icon'
import MenuTopBar from '../elements/MenuTopBar'
import Spacer from '../elements/Spacer'

type SideMenuProps = {
	logout: () => void
	orgId: string
}

const SideMenu: FunctionComponent<SideMenuProps> = ({ logout, orgId }) => {
	const toggleMenu = () => {
		// document.body.classList.toggle('menuopen')
	}
	const onClick = e => {
		const clickedElemClasslist = (e.target as HTMLElement).classList
		if (clickedElemClasslist.contains('spacer') || clickedElemClasslist.contains('menu-top-bar')) {
			toggleMenu()
		}
	}
	return (
		<div className="admin-side-menu" onClick={onClick}>
			<div className="side-menu__logo" />
			{/* <MenuTopBar /> */}
			{orgId === 'cognitas' ? (
				<SideMenuItem target="/admin/organisations" icon="building">
					Organisations
				</SideMenuItem>
			) : null}
			<SideMenuItem target="/admin/users" icon="user">
				Users
			</SideMenuItem>
			<SideMenuItem target="/admin/contacts" icon="phone">
				Contacts
			</SideMenuItem>
			<SideMenuItem target="/admin/contactgroups" icon="phone">
				Contact Groups
			</SideMenuItem>
			<SideMenuItem target="/admin/incidents" icon="bolt">
				Incidents
			</SideMenuItem>
			<SideMenuItem target="/menu" icon="arrow-right-to-bracket">
				Go to app
			</SideMenuItem>
			<Spacer />
			<SideMenuItem action={logout} icon="power-off" confirmation="Are you sure you wish to log out?">
				Log out
			</SideMenuItem>
		</div>
	)
}

// export default SideMenu

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	orgId: state?.login?.schoolid,
})
const actions = {
	logout: logout,
}
export default connect(mapStateToProps, actions)(SideMenu)

// -------------------------------------------------------------------------------------------------
type SideMenuItemProps = {
	target?: string
	confirmation?: string
	action?: () => void
	icon?: string
}

const SideMenuItem: FunctionComponent<SideMenuItemProps> = ({ children, target, confirmation, action, icon }) => {
	const navigate = useNavigate()

	const onClick = () => {
		if (confirmation) {
			confirm({
				title: 'Confirm',
				text: confirmation,
				confirmText: 'OK',
			}).then(confirmed => {
				if (!confirmed) return
				if (target) navigate(target)
				if (action) action()
				// document.body.classList.remove('menuopen')
			})
			return
		}
		navigate(target)
		// document.body.classList.remove('menuopen')
	}

	return (
		<div className="side-menu-item" onClick={onClick}>
			{icon ? <Icon name={icon} /> : null}
			{children}
		</div>
	)
}
