import React, { FunctionComponent, useRef } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createIncidentContact } from '../../../actions/contacts'

import { Contact, Form, StateTree } from '../../../types'
import { alert, confirm } from '../../../util/alerts'
import Scroller from '../../elements/Scroller'
import Button from '../../elements/Button'
import Input from '../../elements/Input'
import Page from '../../elements/Page'

type AddIncidentContactProps = {
	createIncidentContact: (contact: Contact) => void
}

// =================================================================================================

const AddIncidentContact: FunctionComponent<AddIncidentContactProps> = props => {
	const form = useRef<Form>({ data: {} })

	const { createIncidentContact } = props

	const navigate = useNavigate()

	const onSubmit = () => {
		confirm({
			title: 'Confirm',
			text: 'Create new contact?',
			confirmText: 'Create',
		}).then(confirmed => {
			if (!confirmed) return
			createIncidentContact(form.current.data as Contact)
			alert({ title: 'New contact created' })
			navigate(-1)
		})
	}

	const onCancel = () => {
		confirm({
			title: 'Confirm',
			text: 'Exit without saving changes?',
			confirmText: 'Exit',
		}).then(confirmed => {
			if (!confirmed) return
			navigate(-1)
		})
	}

	return (
		<Page className="add-incident-contact" showBackButton title="New Incident Contact">
			<Scroller>
				<Input id="name" form={form.current} label="Name" />
				<Input id="title" form={form.current} label="Description (optional)" />
				<Input id="phone" form={form.current} label="Phone number" />
			</Scroller>
			<footer>
				<Button onClick={onCancel}>Exit</Button>
				<Button onClick={onSubmit}>Save</Button>
			</footer>
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	contactGroups: state.contact_groups || [],
})
const actions = {
	createIncidentContact: createIncidentContact,
}
export default connect(mapStateToProps, actions)(AddIncidentContact)
