import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useMount } from 'react-use'
import Button from '../elements/Button'
import Preloader from '../elements/PreloaderBricks'

type PreloaderSectionProps = {
	dataloader: () => Promise<any>
	className?: string
	reloadkey?: string
}

const PreloaderSection: FunctionComponent<PreloaderSectionProps> = props => {
	const { className, dataloader, children, reloadkey } = props

	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<string>(null)

	const reload = async () => {
		setLoading(true)
		setError(null)
		try {
			await dataloader()
		} catch (err) {
			setError(error)
		}
		setLoading(false)
	}

	useEffect(() => {
		reload()
	}, [reloadkey])

	let _className = 'preloader-section'
	if (className) _className += ' ' + className

	if (loading) {
		return (
			<div className={_className}>
				<Preloader />
			</div>
		)
	}
	if (error) {
		return (
			<div className={_className}>
				<p>An error occurred</p>
				<Button onClick={reload}>Retry</Button>
			</div>
		)
	}
	return <>{children}</>
}

export default PreloaderSection
