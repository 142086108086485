import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Role, StateTree } from '../../../types'
import Button from '../../elements/Button'
import ListItem, { ListItemColours } from '../../elements/ListItem'
import Page from '../../elements/Page'
import Row from '../../elements/Row'

type RoleActionsProps = {
	roles: Role[]
	online: boolean
}

const routes = [
	{
		target: '/contacts/live',
		title: 'Live incident contacts',
		colour: 'red',
		subtitle: 'Find/add contacts for the incident in progress',
		icon: 'phone',
	},
	{
		target: '/incidents/new',
		title: 'Notification of incident',
		colour: 'red',
		subtitle: 'Create initial record of an active incident',
		icon: 'bolt',
	},
	{
		target: '/initial-action',
		title: 'Initial action',
		colour: 'green',
		subtitle: 'Create initial record of an active incident',
		icon: 'bolt',
	},
	{
		target: '/roles-allocation',
		title: 'Allocation of roles',
		colour: 'blue',
		subtitle: 'Check roles are allocated to appropriate staff',
		icon: 'users',
	},
	{
		target: '/logs',
		title: 'Logs',
		colour: 'green',
		subtitle: 'Access incident logs',
		icon: 'pen-to-square',
	},
	{
		target: '/checklist/:role',
		title: 'Checklist',
		colour: 'red',
		subtitle: 'responsibilities checklist',
		icon: 'clipboard-list',
	},
	{
		target: '/info/communications',
		title: 'Communications',
		colour: 'blue',
		subtitle: 'Communications procedure information',
		icon: 'phone',
	},
	{
		target: '/info/business-continuity',
		title: 'Business continuity',
		colour: 'blue',
		subtitle: 'Backup arrangements and continuity procedures',
		icon: 'infinity',
	},
	{
		target: '/info/evacuation-procedure',
		title: 'Evacuation procedure',
		colour: 'blue',
		subtitle: 'School evacuation procedures',
		icon: 'person-walking',
	},
	{
		target: '/info/site-information',
		title: 'Site information',
		colour: 'blue',
		subtitle: 'School building and services information',
		icon: 'faucet-drip',
	},
	{
		target: '/checklist/post-incident-support',
		title: 'Post incident support',
		colour: 'blue',
		subtitle: 'Checklist for post incident support',
		icon: 'heart-pulse',
	},
]

const DATA = {
	'incident-manager': {
		title: 'Incident Manager',
		targets: ['/contacts/live', '/incidents/new', '/roles-allocation', '/logs', '/checklist/:role'],
	},
	'cmt-support-officer': {
		title: 'CMT Support Officer',
		targets: ['/contacts/live', '/incidents', '/roles-allocation', '/logs', '/checklist/:role'],
	},
	'log-keeper': {
		title: 'Log Keeper',
		targets: ['/contacts/live', '/logs', '/checklist/:role'],
	},
	'communications-manager': {
		title: 'Communications Manager',
		// targets: ['/contacts/live', '/logs', '/checklist/:role', '/info/communications'],
		targets: ['/contacts/live', '/logs', '/checklist/:role'],
	},
	'media-manager': {
		title: 'Media Manager',
		targets: ['/contacts/live', '/logs', '/checklist/:role', '/info/communications'],
	},
	// 'business-continuity-manager': {
	// 	title: 'Business Continuity Manager',
	// 	targets: ['/contacts/live', '/logs', '/checklist/:role', '/info/business-continuity'],
	// },
	'welfare-manager': {
		title: 'Welfare Manager',
		targets: ['/contacts/live', '/logs', '/checklist/:role'],
	},
	'site-coordinator': {
		title: 'Site Coordinator',
		targets: ['/contacts/live', '/logs', '/checklist/:role'],
	},
	'educational-visit-manager': {
		title: 'Educational Visit Manager',
		targets: ['/contacts/live', '/logs', '/checklist/:role'],
	},
}

const RoleActions: FunctionComponent<RoleActionsProps> = props => {
	const { roles, online } = props
	let { roleid } = useParams()
	const role = roles.find(r => r.id === roleid)

	const data = DATA[roleid]
	const { targets, title } = data
	return (
		<Page className="role-actions" title={title} subtitle="Action plan" showBackButton>
			<p>The following is a list of all relevant information, actions and responsibilities for this role.</p>
			{targets.map(t => {
				const route = routes.find(r => r.target === t)
				if (!route) return
				const { title: routeTitle, icon } = route
				const { colour } = role
				let { subtitle, target } = route
				// Replace role ID in URL for responsibilities checklist
				if (target === '/checklist/:role') {
					subtitle = title + ' ' + subtitle
					target = target.replace(':role', roleid)
				}
				let enabled = true
				// if (target === '/incidents/new' && !online) {
				// 	enabled = false
				// 	subtitle = 'You must be online to create a new incident'
				// }
				return (
					<ListItem key={target} title={routeTitle} target={target} colour={colour} icon={icon} enabled={enabled}>
						{subtitle}
					</ListItem>
				)
			})}
		</Page>
	)
}

// Notification of Incident
// Initial Action
// Allocation of Roles
// Log
// Responsibilities Checklist
// App 4 Communications
// App 5 Business Continuity
// App 6 Evacuation Procedure
// App 7 Shelter
// App 8 Site Information
// App 10 Lockdown
// App 11 School Closure
// App 14 Post Incident Support

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	roles: state.roles,
	online: state.online,
})
const actions = {}
export default connect(mapStateToProps, actions)(RoleActions)
