import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { Content, StateTree } from '../../types'

import ListItem from '../elements/ListItem'
import Page from '../elements/Page'

type ThreatsProps = {
	content: Content
}

const Threats: FunctionComponent<ThreatsProps> = ({ content }) => {
	const threats = content?.threats || []

	return (
		<Page className="threats" title="Critical Actions" showBackButton={false}>
			{threats.map((threat: any) => {
				const { path, name, icon, colour, subtitle } = threat
				return (
					<ListItem key={path} title={name} icon={icon} colour={colour} target={`/threats/${path}`}>
						{subtitle}
					</ListItem>
				)
			})}
			{!threats.length && <p>No pages to display</p>}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return { content: state?.content || {} }
}
const actions = {}
export default connect(mapStateToProps, actions)(Threats)
