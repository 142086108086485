import { Reducer } from '../types'

const INITIAL_STATE = []

export const reducers = {
	CONTACT_CREATED: (state, action) => ({
		...state,
		incident_contacts: [...(state.incident_contacts || []), action.payload],
	}),
	CONTACT_SELECTED: (state, action) => ({ ...state, selectedContact: action.payload }),
	CONTACTS_FETCHED: (state, action) => ({ ...state, contacts: action.payload }),
	CONTACT_UPDATED: (state, action) => {
		const contacts = [...state.contacts]
		const index = contacts.findIndex(contact => contact.id === action.payload.id)
		if (index >= 0) contacts[index] = action.payload
		else contacts.push(action.payload)
		return { ...state, contacts }
	},
	CONTACT_DELETED: (state, action) => ({ ...state, contacts: state.contacts.filter(c => c.id !== action.payload.id) }),

	CONTACT_GROUPS_FETCHED: (state, action) => ({ ...state, contact_groups: action.payload }),
	CONTACT_GROUP_SELECTED: (state, action) => ({ ...state, selectedContactGroup: action.payload }),
	CONTACT_GROUP_UPDATED: (state, action) => {
		const contact_groups = [...state.contact_groups]
		const index = contact_groups.findIndex(group => group.id === action.payload.id)
		if (index >= 0) contact_groups[index] = action.payload
		else contact_groups.push(action.payload)
		return { ...state, contact_groups }
	},
	CONTACT_GROUP_DELETED: (state, action) => ({
		...state,
		contact_groups: state.contact_groups.filter(c => c.id !== action.payload.id),
	}),
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (!newState.incident_contacts) {
		newState.incident_contacts = INITIAL_STATE
	}
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
