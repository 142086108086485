import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Form, StateTree, Incident } from '../../types'
import { selectIncident } from '../../actions/incidents'
import Popup from '../elements/Popup'
import IncidentForm from '../modules/IncidentForm'
import { useMount } from 'react-use'
import formatDateTime from '../../util/formatDateTime'

type IncidentPopupProps = {
	selectedIncident: Incident
	selectIncident: (incident: Incident) => void
	// updateIncident: (incident: Incident) => Promise<any>
}

const IncidentPopup: FunctionComponent<IncidentPopupProps> = props => {
	// const { updateIncident, selectedIncident, selectIncident } = props
	const { selectedIncident, selectIncident } = props
	const form = useRef<Form>({
		data: selectedIncident || {
			incidentReportDateTime: formatDateTime(new Date()),
			incidentDateTime: formatDateTime(new Date()),
		},
	})

	useEffect(() => {
		form.current.data = selectedIncident || {}
	}, [selectedIncident])

	const newIncident = !selectedIncident.id

	const [loading, setLoading] = useState(false)

	const onSave = async () => {
		setLoading(true)
		try {
			// await updateIncident({ ...selectedIncident, ...form.current.data })
			selectIncident(null)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}

	return (
		<Popup className="incident-details-popup" onClose={() => selectIncident(null)} onSave={onSave} loading={loading}>
			<IncidentForm form={form.current} />
		</Popup>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	selectedIncident: state.selectedIncident,
})
const actions = {
	// updateIncident: updateIncident,
	selectIncident: selectIncident,
}
export default connect(mapStateToProps, actions)(IncidentPopup)
