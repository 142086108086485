import React, { FunctionComponent, KeyboardEvent, useState } from 'react'
import { Incident, StateTree } from '../../types'

import { addLog } from '../../actions/logs'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-autosize-textarea/lib'

import MyGoogleMap from '../elements/MyGoogleMap'
import Row from '../elements/Row'
import Button from '../elements/Button'
import Icon from '../elements/Icon'
import getCurrentLocation from '../../util/getCurrentLocation'
import { useMount } from 'react-use'

type AddIncidentLogProps = {
	incident: Incident
	addLog: (incidentId: string, type: string, content: string) => void
}

const SCHOOL_LOCATION = { lat: 25.238272956112844, lng: 55.317324852540956 }

const AddIncidentLog: FunctionComponent<AddIncidentLogProps> = props => {
	const { incident, addLog } = props
	const [content, setContent] = useState('')
	const [location, setCurrentLocation] = useState(null)

	useMount(() => getUserLocation())

	async function getUserLocation() {
		const userLocation = await getCurrentLocation()
		if (userLocation) setCurrentLocation(userLocation)
	}

	const onClickSubmit = () => {
		addLog(incident.id, 'text', content)
		setContent('')
	}

	const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			onClickSubmit()
			e.preventDefault()
		}
	}

	const shareLocation = () => {
		addLog(incident.id, 'location', JSON.stringify(location))
	}

	let className = 'add-incident-log'
	// if (!enabled) className += ' add-incident-log--disabled'
	const placeholder = 'Enter log entry...'
	return (
		<div className={className}>
			<Row>
				<div className="add-incident-log__wrapper">
					<TextareaAutosize
						onChange={e => setContent((e.target as HTMLTextAreaElement).value)}
						onKeyPress={onKeyPress}
						placeholder={placeholder}
						// disabled={!enabled}
						value={content}
					/>
				</div>
				<Icon name="paper-plane-top" onClick={onClickSubmit} />
				{location && <Icon name="map-marker-alt" onClick={shareLocation} />}
			</Row>
			{!location && <p>User location unavailable</p>}
		</div>
	)
}

// export default AddIncidentLog

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	logs: state.logs,
	incident: state.activeIncident,
})
const actions = {
	addLog: addLog,
}
export default connect(mapStateToProps, actions)(AddIncidentLog)
