import { Reducer } from '../types'

import organisationsReducer from './organisations'
import checklistsReducer from './checklists'
import incidentsReducer from './incidents'
import contactsReducer from './contacts'
import loginReducer from './login'
import rolesReducer from './roles'
import usersReducer from './users'
import logsReducer from './logs'
import miscReducer from './misc'
import navReducer from './nav'

const delegator: Reducer = (state, action) => {
	let newState = state

	newState = organisationsReducer(newState, action)
	newState = checklistsReducer(newState, action)
	newState = incidentsReducer(newState, action)
	newState = contactsReducer(newState, action)
	newState = loginReducer(newState, action)
	newState = usersReducer(newState, action)
	newState = rolesReducer(newState, action)
	newState = logsReducer(newState, action)
	newState = miscReducer(newState, action)
	newState = navReducer(newState, action)

	if (!newState) {
		console.error(`Error: No state after web reducer: ${action.type}`)
	}

	if (window) window.state = newState

	return newState
}

export default delegator
