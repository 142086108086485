import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import parse from 'try-json'

import { Log, Role, RoleAllocation, StateTree, User } from '../../types'
import { formatLocation } from '../../util/formatLocation'
import Icon from './Icon'
import Row from './Row'

type LogProps = {
	log: Log
	users: User[]
	allocations: RoleAllocation[]
	roles: Role[]
	onClick?: (log: Log) => void
}

const Log: FunctionComponent<LogProps> = props => {
	const { log, users, onClick, allocations, roles } = props
	const { content, id, timestamp, type, userid, incidentid } = log

	const user = users.find(u => u.id === userid) || ({} as User)
	const { name = '' } = user
	const roleAllocation = allocations.find(a => a.userid === userid)
	const role = roleAllocation ? roles.find(r => r.id === roleAllocation.roleid) : null
	const colour = role?.colour || 'black'

	const formatDateTime = date => {
		const d = new Date(date)
		return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
	}

	let _content: JSX.Element | string = content
	if (type === 'location') {
		_content = (
			<div className="log__location-button">
				<Icon name="map-marker" /> Shared location{' '}
				<div className="log__location-detail">{formatLocation(parse(content))}</div>
			</div>
		)
	}

	return (
		<div className="log" onClick={() => onClick(log)}>
			<Row>
				<div className="log__icon">
					<div className={`log__dot log__dot--${colour}`}></div>
				</div>
				<div className="log__left">
					<div className="log__author">{name}</div>
				</div>
				<div className="log__right">
					<div className="log__time">{formatDateTime(timestamp)}</div>
				</div>
			</Row>
			<div className="log__content">{_content}</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	users: state.users || [],
	roles: state.roles || [],
	allocations: state.role_allocations,
})
const actions = {}
export default connect(mapStateToProps, actions)(Log)
