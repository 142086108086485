import React, { FunctionComponent, useRef } from 'react'

import { createIncident } from '../../../actions/incidents'
import IncidentForm from '../../modules/IncidentForm'
import Scroller from '../../elements/Scroller'
import Button from '../../elements/Button'
import Page from '../../elements/Page'
import { Form, IncidentDetail, StateTree } from '../../../types'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { alert, confirm } from '../../../util/alerts'
import formatDateTime from '../../../util/formatDateTime'
// import FormFromDefinition from '../../modules/FormFromDefinition'

type IncidentNewProps = {
	createIncident: (incidentDetails: IncidentDetail) => void
	online: boolean
}

const IncidentNew: FunctionComponent<IncidentNewProps> = props => {
	const { createIncident, online } = props

	const now = formatDateTime(new Date())
	const form = useRef<Form>({
		data: {
			incidentReportDateTime: now,
			incidentDateTime: now,
		},
	})
	const navigate = useNavigate()

	const onSubmit = () => {
		confirm({
			title: 'Confirm',
			text: 'Please confirm that you wish to report this incident?',
			confirmText: 'Confirm',
		}).then(async confirmed => {
			if (!confirmed) return
			await createIncident(form.current.data as IncidentDetail)
			alert({ title: 'New incident created' })
			navigate(-1)
		})
	}

	const onCancel = () => {
		confirm({
			title: 'Confirm',
			text: 'Exit without saving changes?',
			confirmText: 'Exit',
		}).then(confirmed => {
			if (!confirmed) return
			navigate(-1)
		})
	}

	return (
		<Page className="incident-new" title="New Incident" showBackButton={false} padding={false}>
			<Scroller>
				<p>
					Please use the following form to report details of an incident. These details can be amended at any time until
					the incident has been closed.
				</p>
				<IncidentForm form={form.current} enabled={online} />
			</Scroller>
			{online && (
				<footer>
					<Button onClick={onCancel}>Exit</Button>
					<Button onClick={onSubmit}>Save</Button>
				</footer>
			)}
		</Page>
	)
}

// =================================================================================================

// =================================================================================================

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	online: state.online,
})
const actions = {
	createIncident: createIncident,
}
export default connect(mapStateToProps, actions)(IncidentNew)
