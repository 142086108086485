import React, { FunctionComponent } from 'react'
import { Organisation, StateTree } from '../../types'

import { getOrganisations, setSelectedOrganisation } from '../../actions/organisations'
import { connect } from 'react-redux'
import { useMount } from 'react-use'

type OrganisationSelectorProps = {
	setSelectedOrganisation: (organisation: Organisation) => void
	getOrganisations: () => Promise<void>
	onChange?: (organisationId: string) => void
	organisations: Organisation[]
	selectedOrganisation: Organisation
	orgId: string
}

const OrganisationSelector: FunctionComponent<OrganisationSelectorProps> = props => {
	const { orgId, organisations, getOrganisations, onChange, setSelectedOrganisation, selectedOrganisation } = props

	const isSuperAdmin = orgId === 'cognitas'
	if (!isSuperAdmin) return null

	useMount(getOrganisations)

	const _onChange = e => {
		const orgId = e.currentTarget.value
		if (onChange) onChange(orgId)
		setSelectedOrganisation(organisations.find(o => o.id === orgId))
	}

	return (
		<div className="organisations-selector">
			<label>
				Selected organisation:
				<select onChange={_onChange} value={selectedOrganisation?.id}>
					{organisations.map(org => (
						<option key={org.id} value={org.id}>
							{org.name}
						</option>
					))}
				</select>
			</label>
		</div>
	)
}

// export default OrganisationSelector

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	organisations: state.organisations || [],
	selectedOrganisation: state.selectedOrganisation,
	orgId: state?.login?.schoolid,
})
const actions = {
	getOrganisations: getOrganisations,
	setSelectedOrganisation: setSelectedOrganisation,
}
export default connect(mapStateToProps, actions)(OrganisationSelector)
