import React, { FunctionComponent } from 'react'
import { Form } from '../../types'

export type SelectProps = {
	id: string
	label: string
	initialValue?: string
	type?: string
	form: Form
	enabled?: boolean
	options: { key: string; value: string }[]
	onChange?: (val: string) => void
}

const Select: FunctionComponent<SelectProps> = props => {
	const { id, label, initialValue = '', form, enabled = true, type = 'text', onChange, children } = props
	const [value, setValue] = React.useState(typeof form.data[id] === 'undefined' ? initialValue || '' : form.data[id])
	const onChangeInternal = e => {
		setValue(e.target.value)
		form.data = form.data || {}
		form.data[id] = e.target.value
		if (onChange) onChange(e.target.value)
	}
	const _label = label + (label.endsWith('?') ? '' : ':')
	return (
		<label>
			{_label}
			<select name={id} value={value} onChange={onChangeInternal} disabled={!enabled} autoComplete={id}>
				{props.options.map(option => (
					<option key={option.key} value={option.key}>
						{option.value}
					</option>
				))}
			</select>
			{children}
		</label>
	)
}

export default Select
