import { Reducer } from '../types'

export const reducers: Record<string, Reducer> = {
	ORGANISATIONS_FETCHED: (state, action) => ({
		...state,
		organisations: action.payload,
		selectedOrganisation: state.selectedOrganisation || action?.payload?.[0],
	}),
	ORGANISATION_UPDATED: (state, action) => {
		const orgs = [...state.organisations]
		const org = action.payload
		const index = orgs.findIndex(o => o.id === org.id)
		if (index >= 0) orgs[index] = org
		else orgs.push(org)
		return { ...state, organisations: orgs }
	},
	ORGANISATION_SELECTED: (state, action) => ({ ...state, selectedOrganisation: action.payload }),
	ORGANISATION_EDIT_SELECTED: (state, action) => ({ ...state, selectedOrganisationForEdit: action.payload }),
}

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
