import React, { FunctionComponent } from 'react'
import Preloader from './PreloaderBricks'

type PreloaderOverlayProps = {
	//
}

const PreloaderOverlay: FunctionComponent<PreloaderOverlayProps> = props => {
	return (
		<div className="preloader-overlay">
			<Preloader />
		</div>
	)
}

export default PreloaderOverlay
