import { v4 as uuid } from 'uuid'
import { ActionCreator, Log } from '../types'
import { getLogs as getLogsApi, addLog as addLogApi } from '../util/api'

export const addLog: ActionCreator =
	(incidentId: string, type: string, content: string) => async (dispatch, getState) => {
		console.log('content', content)

		const state = getState()
		if (!state?.activeIncident?.id) return
		const userid = state.login.userid

		const { roles, role_allocations } = state
		const roleAllocation = role_allocations.find(a => a.userid === userid)
		const role = roleAllocation ? roles.find(r => r.id === roleAllocation.roleid) : null
		const roleid = role ? role.id : null
		const timestamp = new Date().toISOString()
		const id = uuid()
		const incidentid = state.activeIncident.id
		const newLog: Log = { id, timestamp, roleid, userid, type, content, incidentid }
		dispatch({ type: 'LOG_ADDED', payload: newLog })
		await addLogApi(incidentId, newLog).catch(err => {
			console.error(err)
		})
	}

export const getLogs: ActionCreator = (incidentId: string) => async dispatch => {
	const logs = await getLogsApi(incidentId)
	dispatch({ type: 'LOGS_FETCHED', payload: logs })
}

export default {
	addLog,
}
