import React, { FunctionComponent } from 'react'
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Content, SimpleObject, StateTree, ThreatItem } from '../../types'
import ThreatForm from '../modules/ThreatForm'
import ListItem from '../elements/ListItem'
import MarkdownImage from '../elements/MarkdownImage'
import MarkdownLink from '../elements/MarkdownLink'
import Page from '../elements/Page'
import ActionCard from '../elements/ActionCard'
import { ActionCardDefinition } from '../elements/ActionCard'

type ThreatContentProps = {
	content: Content
}
const ThreatContent: FunctionComponent<ThreatContentProps> = ({ content }) => {
	// Get the path of this page
	const params = useParams()
	const path = (params['*'] || '').split('/')

	// Get the content for this page out of the global "content" object, based on the path (e.g. fire/evacuation/maps)
	let pageContent = (content.threats || []).find((threat: any) => threat.path === path[0]) || ({} as ThreatItem)
	path.slice(1).forEach(pathSegment => {
		pageContent = pageContent?.pages.find((child: any) => child.path === pathSegment)
	})

	// Now we need to work out what should be displayed on this page
	let mainElement = null
	if (pageContent.type === 'form') {
		const formDefinition = pageContent.content as SimpleObject
		mainElement = <ThreatForm formDefinition={formDefinition} />
	} else if (pageContent.type === 'actioncard') {
		const actionCardDefinition = pageContent.content as ActionCardDefinition
		mainElement = <ActionCard definition={actionCardDefinition} />
	} else {
		const markdown = pageContent.content as string
		mainElement = (
			<ReactMarkdown className="markdown" components={{ a: MarkdownLink, img: MarkdownImage }}>
				{markdown}
			</ReactMarkdown>
		)
	}

	// Also display any list items (links to other pages)
	const listItems = pageContent?.pages?.map((page: any) => (
		<ListItem
			key={page.path}
			title={page.name}
			icon={page.icon}
			colour={page.colour}
			target={[...path, page.path].join('/')}
		/>
	))

	return (
		<Page title={pageContent.name}>
			{mainElement}
			{listItems}
		</Page>
	)
}

// export default ThreatContent

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return { content: state?.content || {} }
}
const actions = {}
export default connect(mapStateToProps, actions)(ThreatContent)
