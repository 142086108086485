import { ActionCreator, User } from '../types'
import { alert, confirm } from '../util/alerts'
import * as api from '../util/api'

export const getUsers: ActionCreator = (orgid?: string) => async (dispatch, getState) => {
	const users = (await api.getUsers(orgid)) || []
	dispatch({ type: 'USERS_FETCHED', payload: users })
}

export const updateUser: ActionCreator = (user: User) => async (dispatch, getState) => {
	const orgId = getState().selectedOrganisation?.id || ''
	const response = await api.updateUser(user, orgId)

	if (response.errorStatus) {
		dispatch({ type: 'USER_UPDATE_ERROR', payload: response })
		throw new Error(response.message)
	}
	dispatch({ type: 'USER_UPDATED', payload: user })
}

export const selectUser: ActionCreator = (user: User) => async (dispatch, getState) => {
	dispatch({ type: 'USER_SELECTED', payload: user })
}

export const deleteUser: ActionCreator = (user: User) => async (dispatch, getState) => {
	const orgId = getState().selectedOrganisation?.id || ''
	confirm({ text: 'Are you sure you want to delete this user?' }).then(async result => {
		if (!result) return
		await api.deleteUser(user, orgId)
		alert({ title: 'User deleted' })
		dispatch({ type: 'USER_DELETED', payload: user })
	})
}
