import React, { FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'

import { Route, Routes as RouterRoutes } from 'react-router-dom'
import Login from './components/pages/Login'
import AdminSideMenu from './components/modules/AdminSideMenu'
import Users from './components/pages/admin/Users'
import Contacts from './components/pages/admin/Contacts'
import ContactGroups from './components/pages/admin/ContactGroups'
import Incidents from './components/pages/admin/Incidents'
import Logs from './components/pages/admin/Logs'
import Organisations from './components/pages/admin/Organisations'
import { StateTree } from './types'
import { connect } from 'react-redux'
import { LoginDetails } from './types'
import { useMount } from 'react-use'
import { checkLogin } from './actions/login'

type AdminProps = {
	login: LoginDetails
	checkLogin: () => void
}

const Admin: FunctionComponent<AdminProps> = props => {
	const location = useLocation()

	const { checkLogin } = props
	useMount(() => checkLogin())

	return (
		<>
			<div className="desktop">
				{/* Hide side-menu from login screen */}
				{location.pathname !== '/admin' && <AdminSideMenu />}
				<RouterRoutes location={location} key={location.pathname}>
					<Route path="admin/*">
						<Route index element={<Login userType="admin" />} />
						<Route path="organisations" element={<Organisations />} />
						<Route path="users" element={<Users />} />
						<Route path="contacts" element={<Contacts />} />
						<Route path="contactgroups" element={<ContactGroups />} />
						<Route path="incidents" element={<Incidents />} />
						<Route path="logs/:incidentid" element={<Logs />} />
					</Route>
				</RouterRoutes>
			</div>
		</>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	login: state.login || null,
})
const actions = {
	checkLogin: checkLogin,
}
export default connect(mapStateToProps, actions)(Admin)
