import React, { FunctionComponent } from 'react'
import ListItem from '../elements/ListItem'
import Page from '../elements/Page'
import { Content, StateTree } from '../../types'
import { connect } from 'react-redux'

type InfoProps = {
	content: Content
}

const Info: FunctionComponent<InfoProps> = ({ content }) => {
	const { info = [] } = content
	return (
		<Page title="Information" showBackButton={false}>
			{info.map((item, index) => {
				return (
					<ListItem
						key={index}
						title={item.name}
						target={item.path || item.name.toLowerCase().split(' ').join('-')}
						colour={item.colour}
						icon={item.icon}
					/>
				)
			})}
			{info.length === 0 && <p>No information pages to display</p>}
		</Page>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return { content: state?.content || {} }
}
const actions = {}
export default connect(mapStateToProps, actions)(Info)
