import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import parse from 'try-json'

import { Log, Role, RoleAllocation, StateTree, User } from '../../../types'

import PreloaderSection from '../../modules/PreloaderSection'
import Page from '../../elements/Page'
import { useNavigate, useParams } from 'react-router-dom'
import { getLogs } from '../../../actions/logs'
import formatDateTime from '../../../util/formatDateTime'
import Icon from '../../elements/Icon'
import { formatLocation } from '../../../util/formatLocation'
import GoogleMapPopup from '../../modules/GoogleMapPopup'

type LogsProps = {
	getLogs: (incidentId: string) => Promise<any>
	logs: Log[]
	users: User[]
	roles: Role[]
	allocations: RoleAllocation[]
}

const Logs: FunctionComponent<LogsProps> = props => {
	const { getLogs, logs, users, roles, allocations } = props

	let { incidentid } = useParams()
	let [selectedLog, setSelectedLog] = useState<Log>(null)
	let [selectedMapLocation, setSelectedMapLocation] = useState<string>(null)
	const navigate = useNavigate()

	logs.sort((a, b) => {
		return a.timestamp > b.timestamp ? 1 : -1
	})

	const formatContent = (log: Log) => {
		const { type, content } = log
		let _content: string | JSX.Element = content
		if (type === 'location') {
			_content = (
				<div className="log__location-button" onClick={() => setSelectedMapLocation(content)}>
					<Icon name="map-marker" /> Shared location{' '}
					<div className="log__location-detail">{formatLocation(parse(content))}</div>
				</div>
			)
		}
		return _content
	}

	const onCloseMap = () => {
		setSelectedMapLocation(null)
	}

	const getLoggistName = (log: Log) => {
		const { userid } = log
		const name = users.find(u => u.id === userid)?.name
		return name || userid || 'Unknown'
	}

	const getLoggistRoleDot = (log: Log) => {
		const { userid } = log
		const roleAllocation = allocations.find(a => a.userid === userid)
		const role = roleAllocation ? roles.find(r => r.id === roleAllocation.roleid) : null
		const colour = role?.colour || 'black'
		return (
			<div className={`log__dot log__dot--${colour}`} style={{ display: 'inline-block', marginRight: '8px' }}></div>
		)
	}

	return (
		<PreloaderSection dataloader={() => getLogs(incidentid)}>
			<Page title="Logs" showBackButton={false}>
				<table>
					<thead>
						<tr>
							<th style={{ width: 160 }}>Date/time</th>
							<th style={{ width: 160 }}>Loggist</th>
							<th></th>
							{/* <th>Actions</th> */}
						</tr>
					</thead>
					<tbody>
						{logs.map(log => (
							<tr key={log.id}>
								<td>{formatDateTime(log.timestamp)}</td>
								<td>
									{getLoggistRoleDot(log)}
									{getLoggistName(log)}
								</td>
								<td>{formatContent(log)}</td>
								{/* <td>{log.closed ? 'Closed' : 'Active'}</td> */}
								{/* <td>
									<a href="#" onClick={() => deleteLog(log)}>
									Delete
									</a>
								</td> */}
							</tr>
						))}
					</tbody>
				</table>
			</Page>
			{/* {selectedLog && <LogPopup />} */}
			{selectedMapLocation && (
				<GoogleMapPopup location={JSON.parse(selectedMapLocation)} onClose={onCloseMap} online={true} />
			)}
		</PreloaderSection>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	return {
		logs: state.logs || [],
		users: state.users || [],
		roles: state.roles || [],
		allocations: state.role_allocations || [],
		// selectedLog: state.selectedLog,
	}
}
const actions = {
	getLogs: getLogs,
}
export default connect(mapStateToProps, actions)(Logs)
