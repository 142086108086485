const formatDateTime = (date: Date) => {
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	}
	return new Date(date).toLocaleDateString('en-GB', options)
}

export default formatDateTime
