import { Location } from '../types'

export default async function getCurrentLocation(): Promise<Location> {
	return new Promise<Location>((resolve, reject) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				position => {
					const userLocation = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					}
					resolve(userLocation)
				},
				err => {
					console.error(err)
					resolve(null)
				},
				{ enableHighAccuracy: true }
			)
		}
	})
}
